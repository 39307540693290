import { SET_COMPANY_VISIT_UPDATE_FORM_DATA, CLEAR_COMPANY_VISIT_UPDATE_FORM_DATA } from './actionTypes';

export const setCompanyVisitUpdateFormData = (formData) => ({
  type: SET_COMPANY_VISIT_UPDATE_FORM_DATA,
  payload: formData,
});

export const clearCompanyVisitUpdateFormData = () => ({
  type: CLEAR_COMPANY_VISIT_UPDATE_FORM_DATA,
});
