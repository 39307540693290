import { SET_MY_COMPANY_FORM_DATA, CLEAR_CURRENT_FORM_DATA } from './actionTypes';

export const setMyCompanyFormData = (formData) => ({
  type: SET_MY_COMPANY_FORM_DATA,
  payload: formData,
});

export const clearMyCompanyFormData = () => ({
  type: CLEAR_CURRENT_FORM_DATA,
});
