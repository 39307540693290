import { API_BASE_URL, API_VISITING } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const COMPANY_VISIT = '/company-visit';
const CREATE = '/create';
const GET = '/get';
const GET_DETAILS = '/get-details';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/delete';
const RESTORE = '/restore';

/**
 * Yeni Ziyaret oluşturma fonksiyonu
 * @param {Object} data - Ziyaret oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createCompanyVisit = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${CREATE}`, data);
    console.log("createCompany");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ziyaret eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Ziyaretleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getCompanyVisits = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${GET}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Ziyaretler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Ziyaretleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedCompanyVisits = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${GET_DELETED}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Ziyaretler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Ziyaretleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getCompanyVisit = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${GET}`, { id });
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Ziyaretler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Ziyaretleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getCompanyVisitDetails = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${GET_DETAILS}`, { id });
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Ziyaretler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Ziyaret güncelleme fonksiyonu
 * @param {Object} data - Güncellenen Ziyaret bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateCompanyVisit = async (data) => {
  try {
    console.log("comapnyData");
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Ziyaret güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Ziyaret silme fonksiyonu
 * @param {string} id - Silinmek istenen Ziyaretin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteCompanyVisit = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${DELETE}`, { id });
    return response.data;
  } catch (error) {
    console.error('Ziyaret silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Ziyaret silme fonksiyonu
 * @param {string} companyId - Silinmek istenen Ziyaretin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreCompanyVisit = async (id) => {
  console.log(id);
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_VISITING}${COMPANY_VISIT}${RESTORE}`, { id });
    return response.data;
  } catch (error) {
    console.error('Ziyaret silinirken bir hata oluştu:', error);
    throw error;
  }
};
