import { API_BASE_URL, API_ACCOUNTING } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const OUTCOME = '/outcome';
const CREATE = '/create';
const GET = '/get';
const GET_DETAILS = '/get-details';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/delete';
const RESTORE = '/restore';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} data - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createOutcome = async (data) => {
  const account_id = localStorage.getItem("my_company_id")
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${CREATE}`, {account_id: account_id, ...data});
    console.log("createCompany");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getOutcomes = async () => {
  const id = localStorage.getItem("my_company_id")
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${GET}`, {id});
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedOutcomes = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${GET_DELETED}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getOutcome = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${GET}`, { id });
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getOutcomeDetails = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${GET_DETAILS}`, { id });
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateOutcome = async (data) => {
  try {
    console.log("comapnyData");
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteOutcome = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${DELETE}`, { id });
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreOutcome = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_ACCOUNTING}${OUTCOME}${RESTORE}`, { id });
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};
