// src/store/selectedCountry/selectedCountryActions.js
import { SET_MY_COMPANIES, CLEAR_MY_COMPANIES, UPDATE_UITSLUG_ITEM } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setMyCompanies = (city) => ({
  type: SET_MY_COMPANIES,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearMyCompanies = () => ({
  type: CLEAR_MY_COMPANIES,
});


