import React, { useState, useEffect } from 'react';
import OutcomeForm from './outcomeForm'; // Şirket oluşturma ve düzenleme formu
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { useSelector } from 'react-redux';
import { hasCreatePermission, hasPermission, hasViewPermission } from 'service/common/permissionService';
import OutcomeList from './outcomeList';
import MyCompanyDropdown from '../MyCompanyDropdown/myCompanyDropdown';

const Outcome = (props) => {
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };

  const { t } = useTranslation();
  document.title = t("Income");
  const breadcrumbItems = [
  ];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)



  const formPage =
    <Card className='shadow p-4 rounded-lg'>
      <OutcomeForm onSuccess={handleRefresh} />
    </Card>;

  const listPage =
    <Card className='shadow p-4 rounded-lg'>
      <OutcomeList refresh={refresh} />
    </Card>;

  return (
    <div>
      <div style={{
        margin: "-40px -0px",
        position: "fixed",
        zIndex: 1000
      }}>
        <MyCompanyDropdown />
      </div>
      {hasViewPermission(permissions, "DE_01") ? listPage : null}
      {/* {hasCreatePermission(permissions, "DE_01") ? formPage : null} */}
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(Outcome);