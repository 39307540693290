import React, { useState, useEffect } from 'react';
import { Button, Input, Badge } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { getCountries } from 'service/Definitions/countryService';
import { getCities } from 'service/Definitions/cityService';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission, hasViewPermission } from 'service/common/permissionService';
import { clearSelectedUpdateCountry, setSelectedUpdateCountry } from 'store/depot/selectedUpdateCountry/action';
import { clearSelectedUpdateCity, setSelectedUpdateCity } from 'store/depot/selectedUpdateCity/action';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import 'styles/custom/datatable.css'
import DetailsButton from 'components/Common/Button/detailsButton';
import { useNavigate } from 'react-router-dom';
import { deleteCompanyVisit as deleteCompanyVisit, getCompanyVisits as getCompanyVisits, restoreCompanyVisit } from 'service/visiting/companyVisitService';
import CompanyVisitUpdateForm from './companyVisitUpdateForm';
import { setCompanyVisits } from 'store/visiting/companyVisitList/action';
import { setCompanyVisitUpdateFormData as setCompanyVisitUpdateFormData } from 'store/visiting/companyVisitUpdate/action';

const CompanyVisitList = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const companyVisits = useSelector((state) => state.companyVisits?.list || []);
  const [filteredCompanyVisits, setFilteredCompanyVisits] = useState([]); // State for form data
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyList = await getCompanyVisits();
        dispatch(setCompanyVisits(companyList));
      } catch (error) {
        console.error('Error fetching datas:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  // Seçime göre filteredDatas'i güncelle
  useEffect(() => {
    setFilteredCompanyVisits(companyVisits);
  }, [companyVisits]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    const dataToFilter = companyVisits;
    const filteredData = dataToFilter.filter((company) =>
      company.appointmentBy?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.location?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.visitDate?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.visitorName?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.contactName?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.contactCompanyName?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.evaluation?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.createdAt?.includes(searchText)

    );
    setFilteredCompanyVisits(filteredData);
  }, [searchText, companyVisits]);

  // Handle editing row and open modal
  const handleEdit = async (id) => {
    const selectedData = filteredCompanyVisits.find((data) => data.id === id);
    dispatch(setCompanyVisitUpdateFormData(selectedData));
    dispatch(clearSelectedUpdateCountry());
    dispatch(clearSelectedUpdateCity());

    try {
      const country = await getCountryById(selectedData.country_id);
      const city = await getCityById(selectedData.city_id);
      if (country) {
        dispatch(setSelectedUpdateCountry({ value: country.id, label: country.name }));
      }
      if (city) {
        console.log("city çalıştıııııııııııııııııı");
        dispatch(setSelectedUpdateCity({ value: city.id, label: city.name, country_id: city.country_id }));
      }
    } catch (error) {
      console.log("error");
    }

    // Open modal for editing
    dispatch(
      openUpdateModal({
        body: (
          <CompanyVisitUpdateForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleViewDetails = (id) => {
    navigate(`/visiting/company-visit/${id}`);
  }

  const getCountryById = async (id) => {
    const countries = await getCountries();
    const selectedCountry = countries.find(data => data.id === id);
    return selectedCountry;
  }

  const getCityById = async (id) => {
    const cities = await getCities();
    const selectedCity = cities.find(data => data.id === id);
    return selectedCity;
  }

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteCompanyVisit(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const updatedDatas = await getCompanyVisits(); // Fetch updated datas
            dispatch(setCompanyVisits(updatedDatas)); // Update Redux state
            setFilteredCompanyVisits(updatedDatas); // Update filtered datas
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };


  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          try {
            await restoreCompanyVisit(id); // restore company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });

            const updatedDatas = await getCompanyVisits(); // Fetch updated datas
            dispatch(setCompanyVisits(updatedDatas)); // Update Redux state
            setFilteredCompanyVisits(updatedDatas); // Update filtered datas
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const exportToExcel = () => {
    const column1Name = props.t("Appointment By");
    const column2Name = props.t("Location");
    const column3Name = props.t("Visit Date");
    const column4Name = props.t("Visitor Name");
    const column5Name = props.t("Contact Name");
    const column6Name = props.t("Contact Company Name");
    const column7Name = props.t("Next Meeting Date");
    const column8Name = props.t("Status");


    const selectedColumns = filteredCompanyVisits.map(datas => ({
      [column1Name]: datas.appointmentBy,
      [column2Name]: datas.location,
      [column3Name]: datas.visitDate,
      [column4Name]: datas.visitorName,
      [column5Name]: datas.contactName,
      [column6Name]: datas.contactCompanyName,
      [column7Name]: datas.nextMeetingDate,
      [column8Name]: datas.is_deleted === true ? props.t("Delete") : props.t("Active"),

    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Company Visit List"));
    XLSX.writeFile(workbook, props.t("Company Visit List") + ".xlsx");
  };

  const editIcon = (row) =>
    <Button color="link" onClick={() => handleEdit(row.id)}>
      <FaEdit color="green" size={18} />
    </Button>;

  const deleteIcon = (row) =>
    <Button color="link" onClick={() => handleDelete(row.id)}>
      <FaTrash color="red" size={18} />
    </Button>;

  const restoreIcon = (row) =>
    <Button size='sm' color="success" onClick={() => handleRestore(row.id)}>
      {props.t("Activate")}
    </Button>;

  const columns = [
    {
      name: props.t("Appointment By"),
      selector: (row, rowIndex) =>
        row.appointmentBy,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Location"),
      selector: (row, rowIndex) =>
        row.location,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Visit Date"),
      selector: (row, rowIndex) =>
        row.visitDate ? new Date(row.visitDate).toLocaleDateString() : props.t(""),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Visitor Name"),
      selector: (row, rowIndex) =>
        row.visitorName,
      sortable: true,
    },
    {
      name: props.t("Contact Name"),
      selector: (row, rowIndex) =>
        row.contactName,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Contact Company Name "),
      selector: (row, rowIndex) =>
        row.contactCompanyName,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Next Meeting Date"),
      selector: (row, rowIndex) =>
        row.nextMeetingDate ? new Date(row.nextMeetingDate).toLocaleDateString() : props.t(""),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Status"),
      selector: (row) => row.is_deleted, // Sıralama için ham veriyi kullanır
      sortable: true, // Sıralamayı aktif hale getirir  
      cell: (row) =>
        row.is_deleted === true ?
          <Badge>{props.t("Passive")}</Badge> :
          <Badge color='success'>{props.t("Active")}</Badge>,
      sortFunction: (rowA, rowB) => {
        // "is_deleted" değerine göre sıralama yapar: true (Pasif) veya false (Aktif)
        return rowA.is_deleted === rowB.is_deleted ? 0 : rowA.is_deleted ? 1 : -1;
      },
      wrap: true,
    },

    {
      name: props.t("Actions"),
      cell: (row) => (
        row.is_deleted === false ?
          <>
            {hasViewPermission(permissions, "DE_01") ? <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} /> : <DetailsButton disabled={true} />}
            {hasUpdatePermission(permissions, "MM_01") ? editIcon(row) : null}
            {hasDeletePermission(permissions, "MM_01") ? deleteIcon(row) : null}
          </>
          : restoreIcon(row)
      ),
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div  >
      <h5 className="text-primary mb-4">{props.t("Company Visit List")}</h5>

      <DataTable
        style={{
          border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
        }}
        className='data-table p-3'
        columns={columns}
        data={filteredCompanyVisits}
        customStyles={customStyles}
        pagination
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex align-items-right mb-2'>
            <div className='d-flex'>
              {hasExcelPermission(permissions, "DE_01") && filteredCompanyVisits.length > 0 ? excelButton : null}
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                style={{ width: '250px' }} // Smaller input field
                maxLength={32}
              />
            </div>
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

CompanyVisitList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CompanyVisitList)