/**
 * Listeyi alır ve belirli bir gün sayısına ait öğeleri döndürür.
 * @param {Array} list - Tarih bilgisi içeren liste.
 * @param {string} dateField - ISO formatında tarih alanının adı (örneğin: 'process_date').
 * @param {number} days - Kaç günlük verinin döndürülmesi gerektiği.
 * @returns {Array} Belirtilen gün aralığındaki öğeler.
 */
export const timeFilter = ({ list, dateField, days }) => {
  // Şu anki tarih
  const today = new Date();
  // Belirtilen gün sayısı kadar öncesinin tarihi
  const targetDate = new Date();
  targetDate.setDate(today.getDate() - days);

  console.log("Kaç gün öncesi:", targetDate);
  console.log("Liste:", list);

  // Listeyi filtrele
  return list.filter(item => {
    const itemDate = new Date(item[dateField]); // ISO formatındaki tarihi Date nesnesine çevir
    return itemDate >= targetDate && itemDate <= today; // Belirtilen tarih aralığında mı?
  });
};