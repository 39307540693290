import { SET_COMPANY_VISIT_FORM_DATA, CLEAR_COMPANY_VISIT_FORM_DATA } from './actionTypes';

export const setCompanyVisitFormData = (formData) => ({
  type: SET_COMPANY_VISIT_FORM_DATA,
  payload: formData,
});

export const clearCompanyVisitFormData = () => ({
  type: CLEAR_COMPANY_VISIT_FORM_DATA,
});

