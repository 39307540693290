import React, { useState, useEffect } from 'react';
import CompanyVisitForm from './companyVisitForm'; // Şirket oluşturma ve düzenleme formu
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { useSelector } from 'react-redux';
import { hasCreatePermission, hasPermission, hasViewPermission } from 'service/common/permissionService';
import CompanyVisitList from './companyVisitList';

const Current = (props) => {
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };

  const { t } = useTranslation();
  document.title = t("Company Visits");
  const breadcrumbItems = [
  ];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)



  const companyFormPage =
    <Card className='shadow p-4 rounded-lg'>
      <CompanyVisitForm onSuccess={handleRefresh} />
    </Card>;

  const companyListPage =
    <Card className='shadow p-4 rounded-lg'>
      <CompanyVisitList refresh={refresh} />
    </Card>;

  return (
    <div>
      {hasCreatePermission(permissions, "DE_01") ? companyFormPage : null}
      {hasViewPermission(permissions, "DE_01") ? companyListPage : null}
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(Current);