import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { getCompanies } from 'service/depot/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { openModal, closeModal } from 'store/modal/actions';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import DynamicButton from 'components/Common/Button/dynamicButton';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import HevelenItemList from '../makeHevelen/hevelenItemList';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { setUpdateHevelenFormData } from 'store/depot/updateHevelenForm/action';
import { clearHelevenItemList, setHelevenItemList } from 'store/depot/helevenItemList/action';
import { getHelevenAllData, getHelevensByCompany, updateHeleven } from 'service/depot/helevenService';

const UpdateHevelenForm = props => {
  const dispatch = useDispatch();
  const { source_company_no, id } = useParams();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const updateHevelenFormData = useSelector((state) => state.updateHevelenForm?.formData);
  const hevelenItemList = useSelector((state) => state.helevenItemList?.list || []);
  const [companies, setCompanies] = useState([]);

  const md = 6;
  const lg = 6;
  const xl = 4;

  const dataFormatter = (list) => {
    const formattedList = list?.map(data => ({
      value: data.client_no,
      label: data.name
    }));
    return formattedList;
  }

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchhhh");
      try {
        const dateFormatter = (date) => {
          return date.split('T')[0];
        }
        const hevelenData = await getHelevenAllData(id);
        console.log("update-info");
        console.log(hevelenData);
        const dataByCompany = await getHelevensByCompany(source_company_no);
        console.log("dataByCompany");
        console.log(dataByCompany);
        const companies = await getCompanies();
        const itemsList = handleOutputs(dataByCompany, hevelenData.hevelenItems)
        dispatch(setHelevenItemList(itemsList));
        const { booking_date, ...rest } = hevelenData.hevelen;
        const newData = { ...rest, booking_date: booking_date ? dateFormatter(booking_date) : null }
        dispatch(setUpdateHevelenFormData(newData));
        setCompanies(dataFormatter(companies));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setUpdateHevelenFormData({ ...updateHevelenFormData, [name]: value != "" ? value.toLocaleUpperCase("tr") : null }));
  };

  useEffect(() => {
    console.log("uitslugFormData");
    console.log(updateHevelenFormData);
  }, [updateHevelenFormData]);

  const handleSelectChange = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    console.log("value : " + value);
    dispatch(setUpdateHevelenFormData({ ...updateHevelenFormData, [field]: value }));
  };

  const handleChangeMainCompany = async (field, selectedOption) => {
    if (selectedOption) {
      try {
        const value = selectedOption.value;
        dispatch(setUpdateHevelenFormData({ ...updateHevelenFormData, [field]: value }));
        const dataByCompany = await getHelevensByCompany(value);
        console.log("hevelen listesi");
        console.log(dataByCompany);
        dispatch(setHelevenItemList(dataByCompany));
      } catch (error) {
        console.log(error)
      }
    } else {
      dispatch(setUpdateHevelenFormData({ ...updateHevelenFormData, [field]: null }));
      dispatch(clearHelevenItemList());

    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleConfirm();
  }

  function handleItems(inputArray) {
    if (!Array.isArray(inputArray)) {
      console.log("Input is not an array");
      return [];
    }

    // Yeni dizi oluştur
    const newArray = inputArray
      .filter(item => item.output_count)
      .map(item => ({
        id: item.id,
        inslug_item_id: item.inslug_item_id,
        inslug_id: item.inslug_id,
        output_count: parseInt(item.output_count, 10),
      }));

    return newArray;
  }


  const handleConfirm = async (id) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after failure
          try {
            const items = handleItems(hevelenItemList)
            const { is_deleted, destination_company_name, source_company_name, process_status_id, delivery_company, updatedAt, qr, createdAt, ...rest } = updateHevelenFormData;
            const finalData = { hevelen: rest, hevelenItems: items }
            console.log("finalData");
            console.log(finalData);
            await updateHeleven(finalData);
            navigate(`/hevelens`);
          } catch (error) {
            toast.error(error);
          }
        }
      })
    );
  }

  const sortByOutputCount = (arr) => {
    return arr.sort((a, b) => {
      const aHasValue = a.output_count !== null && a.output_count !== undefined && a.output_count !== '' && a.output_count !== 0;
      const bHasValue = b.output_count !== null && b.output_count !== undefined && b.output_count !== '' && b.output_count !== 0;

      // a dolu ve b boşsa a önce gelir, b dolu ve a boşsa b önce gelir
      return aHasValue === bHasValue ? 0 : aHasValue ? -1 : 1;
    });
  };

  const handleOutputs = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      console.error("Both inputs must be arrays.");
      return arr1;
    }

    // arr2'de output_count değeri olanları filtrele
    const filteredArr2 = arr2.filter(item => item.output_count !== null && item.output_count !== undefined && item.output_count !== '' && item.output_count !== 0);

    // arr1'e output_count değerlerini ekle
    const updatedArr1 = arr1.map(item => {
      const matchingItem = filteredArr2.find(arr2Item => arr2Item.inslug_item_id === item.inslug_item_id);
      if (matchingItem) {
        return { ...item, id: matchingItem.id,  output_count: matchingItem.output_count };
      }
      return item; // eşleşme yoksa olduğu gibi bırak
    });

    const lastData = sortByOutputCount(updatedArr1);
    return sortByOutputCount(updatedArr1);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>

        <Card className='p-4 rounded-lg'>
          <div className='d-flex justify-content-between mb-4'>
            <h5 className="text-primary">{props.t("Update Heleven")}</h5>
            <DynamicButton
              text={props.t("Update")}
              type="submit"
              buttonType={1}
            />
          </div>

          <Row form>
            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="source_company_no">{props.t("Source Company Name")}</Label>
                <DynamicSelect
                  options={companies}
                  isDisabled={true}
                  value={companies.find((data) => data.value === updateHevelenFormData.source_company_no)}
                  onChange={(selected) => {
                    handleChangeMainCompany('source_company_no', selected);
                  }}
                  placeholder={props.t("Select source company")}
                  isClearable
                  required
                  noOptionsMessage={() => props.t("no-option-message")}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    const valueMatch = option.value?.toLowerCase().includes(inputValue.toLowerCase());
                    const labelMatch = option.label?.toLowerCase().includes(inputValue.toLowerCase());
                    return valueMatch || labelMatch;
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="destination_company_no">{props.t("Destination Company Name")}</Label>
                <DynamicCreatableSelect
                  options={companies}
                  value={companies.find((data) => data.value === updateHevelenFormData.destination_company_no)}
                  onChange={(selected) => {
                    handleSelectChange('destination_company_no', selected);
                  }}
                  //onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue) }}
                  placeholder={props.t("Select destination company")}
                  isClearable
                  isDisabled={true}
                  noOptionsMessage={() => props.t("no-option-message")}
                  formatCreateLabel={(inputValue) => `${props.t("Add a new")}: ${inputValue}`}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                    return clientNoMatch || companyNameMatch;
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="booking_date">{props.t("Booking Date")}</Label>
                <DynamicInput
                  type="date"
                  id="booking_date"
                  name="booking_date"
                  value={updateHevelenFormData?.booking_date || ""}
                  onChange={handleInputChange}
                  onClick={(e) => e.target.showPicker()}

                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="extern_document_no">{props.t("Extern Document No")}</Label>
                <DynamicInput
                  type="text"
                  id="extern_document_no"
                  name="extern_document_no"
                  value={updateHevelenFormData?.extern_document_no}
                  placeholder={props.t("Enter extern document no")}
                  onChange={handleInputChange}
                  minLength={3}
                  maxLength={255}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for="description">{props.t("Description")}</Label>
                <DynamicInput
                  type="text"
                  id="description"
                  name="description"
                  value={updateHevelenFormData.description}
                  placeholder={props.t("Enter description")}
                  onChange={handleInputChange}
                  minLength={3}
                  maxLength={255}
                />
              </FormGroup>
            </Col>
          </Row>

          <AcceptModal />
          <FastCreateModal />
        </Card>
        <HevelenItemList />
      </Form >
    </>
  );
};

UpdateHevelenForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateHevelenForm)