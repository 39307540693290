import React, { useEffect, useRef } from "react";
import * as c3 from "c3";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, Row } from "reactstrap";

const MonthlyEarnings = props => {
    const chartRef = useRef(null);

    useEffect(() => {
        // C3 Chart Oluştur
        const chart = c3.generate({
            bindto: chartRef.current,
            data: {
                columns: [
                    [props.t("Income"), 3000],
                    [props.t("Outcome"), 2000],
                    
                ],
                type: "donut",
            },
            donut: {
                title: "",
                width: 32,
                label: { show: false },
            },
            color: {
                pattern: ["#5cb85c", "#d9534f"],
            },
            size: {
                height: 300,
            },
            tooltip: {
                format: {
                    value: (value, ratio, id) => {
                        // Customize tooltip content
                        return `$${value.toLocaleString()}`; // Add dollar sign and format value
                    },
                },
            }
        });

        return () => {
            // Bileşen temizlenirken grafiği yok et
            chart.destroy();
        };
    }, [props]);

    return (
        <Card>
            <CardBody>
                <CardTitle className="h4 mb-4">{props.t("Monthly Income Expense")}</CardTitle>

                <Row className="text-center mt-4">
                    <div className="col-4">
                        <h5 className="font-size-20 text-muted">$56241</h5>
                        <p className="text-muted">{props.t("Income")}</p>
                    </div>
                    <div className="col-4">
                        <h5 className="font-size-20 text-muted">$56241</h5>
                        <p className="text-muted">{props.t("Expense")}</p>
                    </div>
                    <div className="col-4">
                        <h5 className="font-size-20 text-success">$23651</h5>
                        <p className="text-success">{props.t("Profit")}</p>
                    </div>
                </Row>
                <div ref={chartRef}></div>
            </CardBody>
        </Card>
    );
};

MonthlyEarnings.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(MonthlyEarnings)

