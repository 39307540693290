import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../store/modal/actions'; // Import the action to close the modal
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next";
import { setTimer } from 'store/timer/actions';

const AcceptModal = props => {
  const dispatch = useDispatch();

  // Get the modal content from Redux state
  const modalState = useSelector((state) => state.modal);
  const timer = useSelector((state) => state.timerOpen.timer);

  const { isOpen, content } = modalState;

  useEffect(()=>{
    console.log("timer");
    console.log(timer);
    console.log("---------");
  },[timer])


  // Handle modal close
  const handleClose = () => {
    dispatch(closeModal());
  };

  // Handle form submit
  const handleSubmit = (e) => {
    content.onConfirm();  // Onay fonksiyonunu çalıştır
  };

  // Enter tuşuna basıldığında formu submit et
  const handleKeyDown = (e) => {
    try {
      console.log(timer);
    if (e.key === 'Enter') {
      if (timer === true) {
        dispatch(setTimer(false));
        handleSubmit(e); // Formu submit et
        const interval = setInterval(() => {
          dispatch(setTimer(true));
        }, 1000);
      }
    }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <ModalHeader toggle={handleClose}><span style={{color:content.titleColor}}>{content.title}</span></ModalHeader>
        <ModalBody>
          <span style={{color:content.bodyColor}}>{content.body}</span>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>
            {content.confirmLabel || props.t("Confirm")}
          </Button>
          <Button type="submit" color="danger" onClick={handleClose}>
            {content.cancelLabel || props.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </form>
  );
};

AcceptModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(AcceptModal);
