// src/config.js

// export const API_BASE_URL = 'https://back.actnatie.com/api'; 

export const API_BASE_URL = 'https://testback.actnatie.com/api';


/* export const API_BASE_URL = 'http://127.0.0.1:5001/api';
 */
export const API_DEPOT = '/depot';
/* export const API_BASE_URL = 'http://127.0.0.1:5001/api';
 */export const API_SETTINGS = '/settings';
export const API_ACCOUNTING = '/accounting';
export const API_VISITING = '/visiting';




export const MAIN_COMP_NAME = "Antwerp Container Transport";
