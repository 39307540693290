import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setCities } from 'store/depot/Definitions/city/action';
import { getCities, createCity, updateCity } from 'service/Definitions/cityService';
import DynamicInput from 'components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { useTranslation, withTranslation } from "react-i18next"
import { createCountry, getCountries } from 'service/Definitions/countryService';
import { closeModal, openModal } from 'store/modal/actions';
import { clearCityFormData, setCityFormData } from 'store/updates/cityForm/action';
import { useSelector } from 'react-redux';
import { closeUpdateModal } from 'store/updateModal/actions';
import { setAddCompanyFormData } from 'store/depot/companyAddForm/action';
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import { setAddExternCompanyFormData } from 'store/depot/externCompanyAddForm/action';
import { setCreateDepotFormData } from 'store/settings/createDepotForm/action';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { customValidationError } from 'components/Common/customValidationError';
import { setCurrentFormData } from 'store/accounting/current/currentForm/action';

const UpdateCityForm = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const saveButtonRef = useRef(null);

  // Form state, JSON yapısına göre düzenlendi
  //const [formData, setFormData] = useState({});
  const cityUpdateFormData = useSelector((state) => state.cityFormData?.formData || {});
  const addCompanyFormData = useSelector((state) => state.addCompanyFormData?.formData);
  const addExternCompanyFormData = useSelector((state) => state.addExternCompanyFormData?.formData || null);
  const addDepotFormData = useSelector((state) => state.createDepotFormData?.formData || null);

  const isCreate = useSelector((state) => state.isCreate?.isCreate || false);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryList = await getCountries();
        console.log(countries)
        const formattedCountries = countryList.map(country => ({
          value: country.id,
          label: country.name
        }));
        setCountries(formattedCountries)
      } catch (error) {
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);


  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(cityUpdateFormData);
  }, [cityUpdateFormData])

  useEffect(() => {
    // Sayfa açıldığında butona odaklan
    if (saveButtonRef.current) {
      saveButtonRef.current.focus();
    }
  }, []); // Sadece bir kez çalışır


  const handleChange = (e) => {
    dispatch(setCityFormData({
      ...cityUpdateFormData,
      [e.target.name]: e.target.value.toLocaleUpperCase("tr"),
    }));
  };
  

  const handleSelectChange = (field, selected) => {
    dispatch(setCityFormData({ ...cityUpdateFormData, [field]: selected?.value }));
  };

  const handleSubmit = (e) => {
    console.log("handlesubmit çalıştııı");
    e.preventDefault();
    handleConfirm();
  };

  const handleConfirm = async () => {
    if (isCreate === true) {
      console.log("addCity çalıştı");
      addCity();
    } else {
      console.log("updateCity çalıştı");
      handleUpdateCity();
    }
  };

  const handleUpdateCity = () => {
    console.log("cityUpdateFormData");
    console.log(cityUpdateFormData);
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          const { createdAt, updatedAt, country_name, ...rest } = cityUpdateFormData;
          try {
            await updateCity(rest)
              .then(async () => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                const countries = await getCities(); // Ürünleri güncelle
                dispatch(setCities(countries)); // Redux state güncelleme
                //dispatch(clearSelectedCountry());
                dispatch(clearCityFormData());
              })
            dispatch(closeModal()); // Close the modal after successful update
            dispatch(closeUpdateModal());

          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after successful update
            customValidationError(error,props);
          }
        }
      })
    );
  }

  const updateOtherForm = (newCityData) => {
    console.log("other form çalıştıı");
    console.log(props);
    if(props.page === "company") {
      dispatch(setAddCompanyFormData({ ...addCompanyFormData, country_id: newCityData.country_id, city_id: newCityData.id }));
    } 
    else if(props.page === "externCompany") {
      dispatch(setAddExternCompanyFormData({ ...addExternCompanyFormData, country_id: newCityData.country_id, city_id: newCityData.id }));
    }
    else if(props.page === "depot") {
      console.log("depot çalıştıııııııııııı");
      dispatch(setCreateDepotFormData({ ...addDepotFormData, country_id: newCityData.country_id, city_id: newCityData.id }));
    }
    else if(props.page === "current") {
      dispatch(setCurrentFormData({ ...addDepotFormData, country_id: newCityData.country_id, city_id: newCityData.id }));
    }
  }

  const addCity = () => {
    console.log("cityUpdateFormData");
    console.log(cityUpdateFormData);
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await createCity(cityUpdateFormData)
              .then(async (newCity) => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                const cities = await getCities(); // Ürünleri güncelle
                const newCityData = cities.find(data => data.id === newCity.id);
                console.log("newCİTYYYYYY");
                console.log(newCityData);
                const formattedCities = cities.map(city => ({
                  value: city.id,
                  label: city.name,
                  country_id: city.country_id
              }));
                dispatch(setCities(formattedCities)); // Redux state güncelleme
                dispatch(clearCityFormData());
                updateOtherForm(newCityData);
                //dispatch(setSelectedCity({ value: newCityData.id, label: newCityData.name, country_id: newCityData.country_id }));
                dispatch(closeUpdateModal());
              })

          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            //toast.error(t("toast-error-save-data"), { position: "top-right" });
            customValidationError(error,props);
          }
        }
      })
    );
  }

  const handleCreateCountry = (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_10")) {
      toast.error(t("No authority"), { position: "top-right" });
      return;
    }
    console.log("handleCreateCountry çalıştı");
    console.log("inputValueeeee");
    console.log(inputValue);
    dispatch(
      openModal({
        title: t("modal-save-title"),
        body: <span>{t("modal-save-body")}</span>,
        onConfirm: async () => {
          dispatch(closeModal());
          // Yeni bir şirket oluşturmak için:
          const newCountry = {
            name: inputValue,  // Kullanıcının girdiği değer
          };

          await createCountry(newCountry)
            .then(async (addedCountry) => {
              const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
              const formattedCountries = newCountryList.map(country => ({
                value: country.id,
                label: country.name
              }));
              setCountries(formattedCountries);
              const newSelected = newCountryList.find(data => data.id === addedCountry.id);

              //dispatch(setSelectedCountry({ value: newSelected.id, label: newSelected.name }));
              dispatch(setCityFormData({ ...cityUpdateFormData, ["country_id"]: newSelected.id }));
              toast.success(t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              //toast.error(t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error,props);
            }); 
        }
      }));// Modalı açıyoruz
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{isCreate === true ? t("Add City") : t("Update City")}</h5>
          {isCreate === true ?
            <button className='btn btn-md bg-success text-white' ref={saveButtonRef}  type='submit'>
              {t("Save")}
            </button>
            :
            <Button color="warning" type='submit'>
              {t("Update")}
            </Button>}
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="country">{t("Country Name")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_11")} text={props.t("No authority")} />
              {hasViewPermission(permissions, "DE_11") ? */}
                <DynamicSelect
                  options={countries}
                  value={countries.find(data => data.value === cityUpdateFormData.country_id || null)}
                  onChange={(selected) => handleSelectChange("country_id", selected)} // Kategori seçimi
                  onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                  placeholder={props.t("Select country")}
                  noOptionsMessage={() => props.t("no-option-message")}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  isClearable
                  required
                  formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                  filterOption={(option, inputValue) => {
                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                    console.log("country optionnnnnnnn");
                    console.log(option);

                    const companyNameMatch = option.label?.toLowerCase().includes(inputValue.toLowerCase());

                    return companyNameMatch;
                  }}
                />
               {/*  :
                <NoAuthUpdateInput data={cityUpdateFormData.country_name} />
              } */}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="name">{props.t("City Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={cityUpdateFormData.name}
                onChange={handleChange}
                placeholder={props.t("Enter city name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>

    </div>
  );
};

UpdateCityForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  page: PropTypes.string,
}

export default withTranslation()(UpdateCityForm)