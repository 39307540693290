import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import { createCountry, getCountries } from 'service/Definitions/countryService';
import { getCities } from 'service/Definitions/cityService';
import { useSelector } from 'react-redux';
import { closeUpdateModal } from 'store/updateModal/actions';
import { hasCreatePermission } from 'service/common/permissionService';
import { clearSelectedUpdateCountry, setSelectedUpdateCountry } from 'store/depot/selectedUpdateCountry/action';
import { clearSelectedUpdateCity, setSelectedUpdateCity } from 'store/depot/selectedUpdateCity/action';
import { customValidationError } from 'components/Common/customValidationError';
import { setMyCompanies } from 'store/accounting/myCompany/myCompanyList/action';
import { getMyCompanies, updateMyCompany } from 'service/accounting/myCompanyService';

const MyCompanyUpdateForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const selectedUpdateCountry = useSelector((state) => state.selectedUpdateCountry?.selectedUpdateCountry || null);
    const selectedUpdateCity = useSelector((state) => state.selectedUpdateCity?.selectedUpdateCity || null);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({});


    useEffect(() => {
        const {createdAt, updatedAt, is_deleted, designation_name, ...rest} = props.data;
        setFormData(rest);
        const fetchData = async () => {
            try {
                const countryList = await getCountries();
                const cityList = await getCities();
                console.log(countryList);
                console.log(cities);
                const formattedCities = cityList.map(city => ({
                    value: city.id,
                    label: city.name,
                    country_id: city.country_id
                }));

                const formattedCountries = countryList.map(country => ({
                    value: country.id,
                    label: country.name
                }));
                console.log("**********");
                console.log(formattedCities);
                console.log(formattedCountries);
                setCountries(formattedCountries);
                setCities(formattedCities);
            } catch (error) {
                console.log(`error ${error}`);
            }
        }

        fetchData();
    }, [dispatch]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        setFormData({
            ...formData,
            [e.target.name]: inputData,
        });
    };

    useEffect(() => {
        console.log("formData güncellendi");
        console.log(formData);
    }, [formData]);

    const handleChangeLowerCase = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: inputData,
        });
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        setFormData({
            ...formData,
            ["phone"]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm()
    };

    const handleSelectCountryChange = (field, selected) => {
        console.log("country change çalıştııı");
        dispatch(clearSelectedUpdateCity());
        if (selected) {
            dispatch(setSelectedUpdateCountry(selected));
            setFormData({ ...formData, [field]: selected.value, ["city_id"]: null });

        } else {
            dispatch(clearSelectedUpdateCountry());
            setFormData({ ...formData, [field]: null, ["city_id"]: null });
        }
    };

    const handleSelectCityChange = (field, selected) => {
        dispatch(setSelectedUpdateCity(selected));
        setFormData({ ...formData, [field]: selected?.value });
    };

    const handleCreateCountry = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: <span>{props.t("modal-save-body")}</span>,
                onConfirm: async () => {
                    // Yeni bir şirket oluşturmak için:
                    const newCountry = {
                        name: inputValue,  // Kullanıcının girdiği değer
                    };
                    await createCountry(newCountry)
                        .then(async (addedCountry) => {
                            const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                            const newSelected = newCountryList.find(data => data.id === addedCountry.id);
                            const formattedCountries = newCountryList.map(country => ({
                                value: country.id,
                                label: country.name
                            }));
                            setCountries(formattedCountries);
                            dispatch(setSelectedUpdateCountry({ value: newSelected.id, label: newSelected.name }));
                            setFormData({ ...formData, ["country_id"]: newSelected.id, ["city_id"]: null });
                            dispatch(clearSelectedUpdateCity());

                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        })
                        .catch((error) => {
                            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                        }); dispatch(closeModal());
                }
            }));// Modalı açıyoruz
    }

    const handleConfirm = async () => {
        console.log("company add Form Data handle Confirm");
        console.log(formData);
        dispatch(
            openModal({
                title: props.t("modal-update-title"),
                body: props.t("modal-update-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after successful update
                    const { createdAt, updatedAt, country_name, city_name, is_deleted, client_no, ...rest } = formData;
                    console.log("update confirm formdata");
                    console.log(formData);

                    console.log("reessstttt");
                    console.log(rest);
                    await updateMyCompany(rest)
                        .then(async () => {
                            toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                            console.log(formData);
                            const updatedCompanies = await getMyCompanies(); // Fetch updated companies
                            dispatch(setMyCompanies(updatedCompanies)); // Update Redux state
                            dispatch(clearSelectedUpdateCity());
                            dispatch(clearSelectedUpdateCountry());
                            setFormData({});
                            dispatch(closeUpdateModal());
                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            //toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                            customValidationError(error,props);
                        });
                }
            })
        );


    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update Company")}</h5>
                    <Button className='text-white' color="warning" type="submit">
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="name">{props.t("Designation Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter designation name")}
                                maxLength={64}
                                minLength={3}
                                required
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="email">{props.t("Mail")}</Label>
                            <DynamicInput
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChangeLowerCase}
                                placeholder={props.t("Enter mail address")}
                                minLength={3}
                                maxLength={64}

                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="phone">{props.t("Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter phone number")}
                                value={formData.phone}
                                minLength={6}
                                maxLength={24}
                                onChange={value => handlePhone(value)} />
                        </FormGroup>
                    </Col>

                    {/* <Col md={4}>
                        <FormGroup>
                            <Label for="country">{props.t("Country")}</Label>
                            <DynamicSelect
                                options={countries}
                                value={selectedUpdateCountry}
                                onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                                placeholder={props.t("Enter country")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="city">{props.t("City")}</Label>
                            <DynamicSelect
                                options={cities.filter(data => selectedUpdateCountry ? data.country_id === selectedUpdateCountry.value : null)} // Filtreleme işlemi
                                value={selectedUpdateCity}
                                onChange={(selected) => handleSelectCityChange("city_id", selected)} // Kategori seçimi
                                placeholder={props.t("Enter city")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";

                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col> */}

                    <Col md={4}>
                        <FormGroup>
                            <Label for="address">{props.t("Address")}</Label>
                            <DynamicInput
                                type="text"
                                name="address"
                                id="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder={props.t("Enter address")}
                                maxLength={255}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="iban">{props.t("IBAN")}</Label>
                            <DynamicInput
                                type="text"
                                name="iban"
                                id="iban"
                                value={formData.iban}
                                onChange={handleChange}
                                placeholder={props.t("Enter IBAN")}
                                maxLength={48}
                                minLength={6}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="tax_no">{props.t("Tax Number")}</Label>
                            <DynamicInput
                                type="text"
                                name="tax_no"
                                id="tax_no"
                                value={formData.tax_no}
                                onChange={handleChange}
                                placeholder={props.t("Enter tax number")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="tax_office">{props.t("Tax Office")}</Label>
                            <DynamicInput
                                type="text"
                                name="tax_office"
                                id="tax_office"
                                value={formData.tax_office}
                                onChange={handleChange}
                                placeholder={props.t("Enter tax office")}
                                maxLength={64}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

MyCompanyUpdateForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(MyCompanyUpdateForm)