import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Breadcrumb from "./Breadcrumb/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Chat
import chat from "./chat/reducer";

// Calendar
import calendar from "./calendar/reducer";

// Tasks
import tasks from "./tasks/reducer";

import inslugReducer from "./depot/inslug/reducer";
import inslugItemReducer from "./depot/inslugItem/reducer";
import selectedProductsReducer from "./depot/selectedProducts/reducer";
import companyReducer from "./depot/company/reducer";
import modalReducer from "./modal/reducer";
import externCompanyReducer from "./depot/externCompany/reducer";
import updateInslugReducer from "./depot/updateInslug/reducer";
import productReducer from "./depot/product/reducer";
import updateInslugItemReducer from "./depot/updateInslugItem/reducer";
import selectedUpdateProductsReducer from "./depot/selectedUpdateProducts/reducer";
import productGroupReducer from "./depot/productGroup/reducer";
import clientProductReducer from "./depot/clientProduct/reducer";
import countriesReducer from "./depot/Definitions/country/reducer";
import citiesReducer from "./depot/Definitions/city/reducer";
import timerReducer from "./timer/reducer";
import selectedClientProductsReducer from "./depot/selectedClientProducts/reducer";
import selectedUpdateClientProductsReducer from "./depot/selectedUpdateClientProducts/reducer";
import countryFormReducer from "./updates/countryForm/reducer";
import updateModalReducer from "./updateModal/reducer";
import selectedCountryReducer from "./depot/selectedCountry/reducer";
import companyFormReducer from "./updates/companyForm/reducer";
import selectedCityReducer from "./depot/selectedCity/reducer";
import filteredCompanyReducer from "./depot/filteredCompanies/reducer";
import productFormReducer from "./updates/productForm/reducer";
import selectedProductGroupReducer from "./depot/selectedProductGroup/reducer";
import clientProductFormReducer from "./updates/clientProductForm/reducer";
import selectedCompanyReducer from "./depot/selectedCompany/reducer";
import selectedProductReducer from "./depot/selectedProduct/reducer";
import isCreateReducer from "./isUpdate/reducer";
import addCityFormReducer from "./depot/cityAddForm/reducer";
import cityFormReducer from "./updates/cityForm/reducer";
import addCompanyFormReducer from "./depot/companyAddForm/reducer";
import permissionsReducer from "./permissions/reducer";
import selectedImageReducer from "./depot/selectedImage/reducer";
import languagesReducer from "./languages/reducer";
import updateProductGroupFormReducer from "./updates/productGroupForm/reducer";
import supportFormReducer from "./support/reducer";
import userListFormReducer from "./userList/reducer";
import userUpdateFormReducer from "./updates/userForm/reducer";
import filteredRolesReducer from "./settings/filteredRoles/reducer";
import rolesReducer from "./settings/roles/reducer";
import selectedUpdateCountryReducer from "./depot/selectedUpdateCountry/reducer";
import selectedUpdateCityReducer from "./depot/selectedUpdateCity/reducer";
import buttonLoadingReducer from "./buttonLoading/reducer";
import addExternCompanyFormReducer from "./depot/externCompanyAddForm/reducer";
import customsStatusReducer from "./depot/Definitions/customsStatus/reducer";
import customsStatusFormReducer from "./updates/customsStatusForm/reducer";
import carrierTypeFormReducer from "./updates/carrierTypeForm/reducer";
import carrierTypesReducer from "./depot/Definitions/carrierType/reducer";
import loadingStatusReducer from "./depot/Definitions/loadingStatus/reducer";
import loadingStatusFormReducer from "./updates/loadingStatusForm/reducer";
import createDepotFormReducer from "./settings/createDepotForm/reducer";
import depotsReducer from "./settings/depots/reducer";
import updateDepotFormReducer from "./settings/updateDepotForm/reducer";
import createUnitFormReducer from "./settings/createUnitForm/reducer";
import unitsReducer from "./settings/units/reducer";
import updateUnitFormReducer from "./settings/updateUnitForm/reducer";
import createPartFormReducer from "./settings/createPartForm/reducer";
import updatePartFormReducer from "./settings/updatePartForm/reducer";
import partssReducer from "./settings/parts/reducer";
import processStatusReducer from "./depot/Definitions/processStatus/reducer";
import processStatusFormReducer from "./updates/processStatusForm/reducer";
import definitionsReducer from "./depot/Definitions/definitions/reducer";
import definitionItemsReducer from "./depot/Definitions/definitionItems/reducer";
import selectedDefinitionReducer from "./depot/selectedDefinition/reducer";
import selectedExternCityReducer from "./depot/selectedExternCity/reducer";
import selectedDepotCityReducer from "./depot/selectedDepotCity/reducer";
import uitslugFormReducer from "./depot/uitslugStore/uitslugForm/reducer";
import cellsReducer from "./settings/cells/reducer";
import updateCellFormReducer from "./settings/updateCellForm/reducer";
import uitslugItemListReducer from "./depot/uitslugStore/uitslugList/reducer";
import updateUitslugFormReducer from "./depot/uitslugStore/updateUitslugForm/reducer";
import selectedUitslugItemListReducer from "./depot/uitslugStore/selectedUitslugList/reducer";
import stockItemListReducer from "./depot/stockItemList/reducer";
import stockFilterFormReducer from "./depot/stockFilterForm/reducer";
import helevenFormReducer from "./depot/helevenForm/reducer";
import fastCreateModalReducer from "./fastCreateModal/reducer";
import createClientProductFormReducer from "./depot/createClientProductForm/reducer";
import currentFormReducer from "./accounting/current/currentForm/reducer";
import currentUpdateFormReducer from "./accounting/current/currentUpdate/reducer";
import currentsReducer from "./accounting/current/currentList/reducer";
import incomeFormReducer from "./accounting/income/incomeForm/reducer";


import helevenItemListReducer from "./depot/helevenItemList/reducer";
import incomeListReducer from "./accounting/income/incomeList/reducer";
import outcomeFormReducer from "./accounting/outcome/outcomeForm/reducer";
import outcomeListReducer from "./accounting/outcome/outcomeList/reducer";
import updateHevelenFormReducer from "./depot/updateHevelenForm/reducer";
import myCompanyFormReducer from "./accounting/myCompany/myCompanyForm/reducer";
import myCompaniesReducer from "./accounting/myCompany/myCompanyList/reducer";
import myCompanyUpdateFormReducer from "./accounting/myCompany/myCompanyUpdateForm/reducer";

import companyVisitFormReducer from "./visiting/companyVisitForm/reducer";
import companyVisitUpdateForm from "pages/Visiting/CompanyVisit/companyVisitUpdateForm";
import companyVisitUpdateFormReducer from "./visiting/companyVisitUpdate/reducer";
import companyVisitsReducer from "./visiting/companyVisitList/reducer";

const rootReducer = combineReducers({
  Layout,
  Breadcrumb,
  tasks,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,

  // Inslug ve InslugItem
  inslug: inslugReducer,
  updateInslug: updateInslugReducer,
  inslugItems: inslugItemReducer,
  updateInslugItems: updateInslugItemReducer,
  selectedProducts: selectedProductsReducer,
  selectedUpdateProducts: selectedUpdateProductsReducer,

  // Uitslug
  uitslugFormData: uitslugFormReducer,
  uitslugItemList: uitslugItemListReducer,
  updateUitslugFormData: updateUitslugFormReducer,
  selectedUitslugItemList: selectedUitslugItemListReducer,

  // Heleven
  helevenFormData: helevenFormReducer,
  helevenItemList: helevenItemListReducer,
  updateHevelenForm: updateHevelenFormReducer,

  // Stock
  stockItemList: stockItemListReducer,
  stockFilterFormData: stockFilterFormReducer,

  // Company
  companies: companyReducer,
  filteredCompanies: filteredCompanyReducer,
  selectedCompany: selectedCompanyReducer,
  addCompanyFormData: addCompanyFormReducer,

  // Extern Company
  addExternCompanyFormData: addExternCompanyFormReducer,
  externCompanies: externCompanyReducer,

  // Product
  products: productReducer,
  productGroups: productGroupReducer,
  clientProducts: clientProductReducer,
  selectedClientProducts: selectedClientProductsReducer,
  selectedUpdateClientProducts: selectedUpdateClientProductsReducer,
  selectedProductGroup: selectedProductGroupReducer,
  selectedProduct: selectedProductReducer,


  // Accounting
  // Income
  incomeFormData: incomeFormReducer,
  incomeList: incomeListReducer,

  // Outcome
  outcomeFormData: outcomeFormReducer,
  outcomeList: outcomeListReducer,

  // Current
  currents: currentsReducer,
  currentFormData: currentFormReducer,
  currentUpdateFormData: currentUpdateFormReducer,

  // MyCompany
  myCompanies: myCompaniesReducer,
  myCompanyFormData: myCompanyFormReducer,
  myCompanyUpdateFormData: myCompanyUpdateFormReducer,

  //Visiting

  // Company Visit
  companyVisits: companyVisitsReducer,
  companyVisitFormData: companyVisitFormReducer,
  companyVisitUpdateFormData: companyVisitUpdateFormReducer,


  //Definitions
  definitions: definitionsReducer,
  definitionItems: definitionItemsReducer,
  selectedDefinition: selectedDefinitionReducer,
  countries: countriesReducer,
  selectedCountry: selectedCountryReducer,
  selectedUpdateCountry: selectedUpdateCountryReducer,
  selectedUpdateCity: selectedUpdateCityReducer,
  customsStatus: customsStatusReducer,
  carrierTypes: carrierTypesReducer,
  loadingStatus: loadingStatusReducer,
  processStatus: processStatusReducer,
  cities: citiesReducer,
  addCityFormData: addCityFormReducer,
  selectedCity: selectedCityReducer,
  selectedExternCity: selectedExternCityReducer,
  selectedDepotCity: selectedDepotCityReducer,

  // Modal
  modal: modalReducer,
  updateModal: updateModalReducer,
  fastCreateModal: fastCreateModalReducer,

  // Timer
  timerOpen: timerReducer,

  // Button Loading
  buttonLoading: buttonLoadingReducer,

  // Permissions
  permissions: permissionsReducer,

  // Image
  selectedImage: selectedImageReducer,

  // Language
  languages: languagesReducer,

  // Support
  support: supportFormReducer,

  // User
  userList: userListFormReducer,

  // Roles
  roles: rolesReducer,
  filteredRoles: filteredRolesReducer,

  // Depots
  createDepotFormData: createDepotFormReducer,
  updateDepotFormData: updateDepotFormReducer,
  depots: depotsReducer,

  // Units
  createUnitFormData: createUnitFormReducer,
  updateUnitFormData: updateUnitFormReducer,
  units: unitsReducer,

  // Parts
  createPartFormData: createPartFormReducer,
  updatePartFormData: updatePartFormReducer,
  parts: partssReducer,

  // Cell
  cells: cellsReducer,
  updateCellFormData: updateCellFormReducer,

  // Updates
  // isUpdate
  isCreate: isCreateReducer,
  // Country Update
  countryFormData: countryFormReducer,
  // City Update
  cityFormData: cityFormReducer,
  //Company Update
  companyFormData: companyFormReducer,
  // Product Group
  productFormData: productFormReducer,
  // Client Product
  clientProductFormData: clientProductFormReducer,
  createClientProductFormData: createClientProductFormReducer,

  // Product Group
  updateProductGroupFormData: updateProductGroupFormReducer,
  // User
  updateUserFormData: userUpdateFormReducer,
  // Customs Status
  customsStatusFormData: customsStatusFormReducer,
  //Carrier Tyoe
  carrierTypeFormData: carrierTypeFormReducer,
  // Loading Status
  loadingStatusFormData: loadingStatusFormReducer,
  // Process Status
  processStatusFormData: processStatusFormReducer,
});

export default rootReducer;
