import React, { useState, useEffect } from 'react';
import { Badge, Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { setCompanyFormData } from 'store/updates/companyForm/action';
import { openUpdateModal } from 'store/updateModal/actions';
import IncomeUpdateForm from './incomeUpdateForm';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasCreatePermission, hasDeletePermission, hasExcelPermission, hasUpdatePermission, hasViewPermission } from 'service/common/permissionService';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import 'styles/custom/datatable.css'
import { Link, useNavigate } from 'react-router-dom';
import { deleteIncome, getIncomes, restoreIncome } from 'service/accounting/incomeService';
import { setIncomeList } from 'store/accounting/income/incomeList/action';
import RestoreButton from 'components/Common/Button/restoreButton';
import MyCompanyDropdown from '../MyCompanyDropdown/myCompanyDropdown';
import { timeFilter } from 'helpers/accountingHelper/accountingHelper';

const IncomeList = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const incomes = useSelector((state) => state.incomeList?.list || []);
  const [filteredIncomes, setFilteredIncomes] = useState([]); // State for form data
  const [timeFilters, setTimeFilters] = useState([]); // State for form data
  const [selectedTimeFilter, setselectedTimeFilter] = useState(); // State for form data

  const navigate = useNavigate();

  useEffect(() => {
    const dayOptions = [
      { value: 7, label: "Son 7 gün" },
      { value: 14, label: "Son 14 gün" },
      { value: 30, label: "Son 30 gün" },
      { value: 60, label: "Son 60 gün" },
      { value: 90, label: "Son 90 gün" },
      /*       { value: 180, label: "Son 180 gün" },
            { value: 365, label: "Son 365 gün" }, */
    ];
    setselectedTimeFilter(dayOptions[0].value);
    setTimeFilters(dayOptions);
  }, [])

  const handleChangeTimeFilter = (e) => {  
    setselectedTimeFilter(e.target.value);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyList = await getIncomes();
        dispatch(setIncomeList(companyList));
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  // Seçime göre filteredCompanies'i güncelle
  useEffect(() => {
    setFilteredIncomes(incomes);
  }, [incomes]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    const dataToFilter = timeFilter({list:incomes,dateField:"process_date", days:selectedTimeFilter});
    const filteredData = dataToFilter.filter((data) =>
      data.income_item_name.toLowerCase().includes(searchText.toLowerCase()) ||
      data.current_name.toLowerCase().includes(searchText.toLowerCase()) ||
      data.quantity.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      data.price.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      data.currency_name.toLowerCase().includes(searchText.toLowerCase()) ||
      data.payment_method_name.toLowerCase().includes(searchText.toLowerCase()) ||
      data.payment_status_name.toLowerCase().includes(searchText.toLowerCase()) ||
      (data.payment_date && new Date(data.payment_date).toLocaleDateString().includes(searchText))
    );
    setFilteredIncomes(filteredData);
  }, [searchText, incomes, selectedTimeFilter]);

  // Handle editing row and open modal
  const handleEdit = async (id) => {
    const selectedData = filteredIncomes.find((data) => data.id === id);

    try {
    } catch (error) {
      console.log("error");
    }

    // Open modal for editing
    dispatch(
      openUpdateModal({
        body: (
          <IncomeUpdateForm data={selectedData} />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleViewDetails = (id) => {
    navigate(`/company/${id}`);
  }

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteIncome(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const updatedCompanies = await getIncomes(); // Fetch updated companies
            dispatch(setIncomeList(updatedCompanies)); // Update Redux state
            setFilteredIncomes(updatedCompanies); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          try {
            await restoreIncome(id); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });

            const updatedCompanies = await getIncomes(); // Fetch updated companies
            dispatch(setIncomeList(updatedCompanies)); // Update Redux state
            setFilteredIncomes(updatedCompanies); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const exportToExcel = () => {
    const column1Name = props.t("Income Item");
    const column2Name = props.t("Current");
    const column4Name = props.t("Quantity");
    const column7Name = props.t("Price");
    const column5Name = props.t("Currency");
    const column3Name = props.t("Payment Method");
    const column6Name = props.t("Payment Status");
    const column8Name = props.t("Process Date");
    const column9Name = props.t("Payment Date");

    const selectedColumns = filteredIncomes.map(itemCompanies => ({
      [column1Name]: itemCompanies.income_item_name,
      [column2Name]: itemCompanies.current_name,
      [column3Name]: itemCompanies.payment_method_name,
      [column4Name]: itemCompanies.quantity,
      [column5Name]: itemCompanies.currency_name,
      [column6Name]: itemCompanies.payment_status_name,
      [column7Name]: itemCompanies.price,
      [column8Name]: itemCompanies.process_date ? new Date(itemCompanies.process_date).toLocaleDateString().toString() : "",
      [column9Name]: itemCompanies.payment_date ? new Date(itemCompanies.payment_date).toLocaleDateString().toString() : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Income List"));
    XLSX.writeFile(workbook, props.t("Income List") + ".xlsx");
  };

  const columns = [
    {
      name: props.t("Income Item"),
      selector: (row, rowIndex) =>
        row.income_item_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Current"),
      selector: (row, rowIndex) =>
        row.current_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Quantity"),
      selector: (row, rowIndex) =>
        row.quantity,
      sortable: true,
    },
    {
      name: props.t("Price"),
      selector: (row, rowIndex) =>
        row.price,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Currency"),
      selector: (row, rowIndex) =>
        row.currency_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Payment Method"),
      selector: (row, rowIndex) =>
        row.payment_method_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Payment Status"),
      selector: (row, rowIndex) =>
        row.payment_status_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Payment Date"),
      selector: (row, rowIndex) =>
        row.payment_date ? new Date(row.payment_date).toLocaleDateString() : "",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Status"),
      selector: (row) => row.is_deleted, // Sıralama için ham veriyi kullanır
      sortable: true, // Sıralamayı aktif hale getirir  
      cell: (row) =>
        row.is_deleted === true ?
          <Badge>{props.t("Passive")}</Badge> :
          <Badge color='success'>{props.t("Active")}</Badge>,
      sortFunction: (rowA, rowB) => {
        // "is_passive" değerine göre sıralama yapar: true (Pasif) veya false (Aktif)
        return rowA.is_deleted === rowB.is_deleted ? 0 : rowA.is_deleted ? 1 : -1;
      },
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) => (
        row.is_deleted === false ?
          <>
            {hasUpdatePermission(permissions, "DE_01") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
            {hasDeletePermission(permissions, "DE_01") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
          </>
          :
          <>
            {hasDeletePermission(permissions, "DE_01") ? <RestoreButton disabled={false} onClick={() => handleRestore(row.id)} /> : <DeleteButton disabled={true} />}
          </>
      )
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>

      <div className='d-flex align-items-center justify-content-between mb-3 p-2'>
        <h5 className="text-primary">{props.t("Income List")}</h5>
        {hasCreatePermission(permissions, "DE_04") ?
          <Link to={"/accounting/income/create"}>
            <Button className="btn btn-sm mx-2" color="success">{props.t("New Income")}</Button>
          </Link>
          : <Button className="btn btn-sm mx-2" disabled color="secondary">{props.t("New Income")}</Button>}
      </div>
      <DataTable
        style={{
          border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
        }}
        className='data-table p-3'
        columns={columns}
        data={filteredIncomes}
        customStyles={customStyles}
        pagination
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        highlightOnHover
        paginationLa
        subHeader
        subHeaderComponent={
          <div className='d-flex align-items-right mb-2'>
            <div className='d-flex'>
            <Input
                type="select"
                value={selectedTimeFilter}
                onChange={(e) => handleChangeTimeFilter(e)}
                style={{ minWidth: "50px", maxWidth: '250px', marginRight: '10px' }}
              >
                {timeFilters?.map((data) => (
                  <option key={data.value} value={data.value}>
                    {props.t(data.label)}
                  </option>
                ))}
              </Input>
              {hasExcelPermission(permissions, "DE_01") && filteredIncomes.length > 0 ? excelButton : null}
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                style={{ minWidth: '100px'}} // Smaller input field
                maxLength={32}
              />
            </div>
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

IncomeList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(IncomeList)