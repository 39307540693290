import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Row, Col, Form, CardBody, Card } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanies } from 'store/depot/company/actions'; // Redux actions
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from 'components/Common/Modals/acceptModal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import DynamicButton from 'components/Common/Button/dynamicButton';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { customValidationError } from 'components/Common/customValidationError';
import ACCOUNTING from 'constants/accountingConstants';
import { getDefinitions, getMultiDefinitions } from 'service/accounting/definitionsService';
import { createDefinition } from 'service/accounting/definitionsService';
import { clearIncomeFormData, setIncomeFormData } from 'store/accounting/income/incomeForm/action';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { createIncome, getIncomes } from 'service/accounting/incomeService';
import { createCurrent, getCurrents } from 'service/accounting/currentService';
import { setIncomeList } from 'store/accounting/income/incomeList/action';
import { useNavigate } from 'react-router-dom';
import MyCompanyDropdown from '../MyCompanyDropdown/myCompanyDropdown';

const IncomeForm = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const formData = useSelector((state) => state.incomeFormData?.formData || null);
    const [incomeItems, setIncomeItems] = useState([]);
    const [currents, setCurrents] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [paymentStatuses, setPaymentStatuses] = useState([]);
    const [quantities, setQuantities] = useState([]);

    const md = 6;
    const lg = 6;
    const xl = 4;

    useEffect(() => {
        handleQuantity();
        handleCurrents();
        handleDefinitions();
    }, []);

    const dataFormatter = (list) => {
        const formattedList = list?.map(data => ({
            value: data.id,
            label: data.name
        }));
        return formattedList;
    }

    const handleDefinitions = async () => {
        const defList = [
            ACCOUNTING.INCOME_ITEM,
            ACCOUNTING.PAYMENT_METHOD,
            ACCOUNTING.PAYMENT_STATUS,
            ACCOUNTING.CURRENCY
        ];
        const defData = await getMultiDefinitions(defList);
        const incomeItems = defData?.find((element) => element.id === ACCOUNTING.INCOME_ITEM)?.sub;
        const paymentMethods = defData?.find((element) => element.id === ACCOUNTING.PAYMENT_METHOD)?.sub;
        const paymentStatuses = defData?.find((element) => element.id === ACCOUNTING.PAYMENT_STATUS)?.sub;
        const currencies = defData?.find((element) => element.id === ACCOUNTING.CURRENCY)?.sub;

        setIncomeItems(dataFormatter(incomeItems));
        setPaymentMethods(dataFormatter(paymentMethods));
        setPaymentStatuses(dataFormatter(paymentStatuses));
        setCurrencies(dataFormatter(currencies));
    }

    const handleCurrents = async () => {
        const currents = await getCurrents();
        setCurrents(dataFormatter(currents));
    }

    const handleQuantity = () => {
        let formattedData = [];
        for (let i = 1; i <= ACCOUNTING.QUANTITY; i++) {
            formattedData.push({
                value: i,
                label: i.toString()
            });
        }
        setQuantities(formattedData);
    }


    useEffect(() => {
        console.log("formData");
        console.log(formData);
    }, [formData]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setIncomeFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handleChangePrice = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");

        // Sadece rakamlar ve '.' karakteri kalsın
        let filteredValue = inputData?.replace(/[^0-9,]/g, "");

        if (filteredValue === "" || filteredValue === '' || filteredValue === null || filteredValue === undefined) {
            dispatch(setIncomeFormData({
                ...formData,
                [e.target.name]: "",
            }));
            return;
        }

        // Eğer ilk karakter '.' ise '0.' yap
        if (filteredValue.startsWith(",")) {
            filteredValue = "0,";
        }

        // Birden fazla '.' varsa yalnızca ilkini tut
        const dotIndex = filteredValue.indexOf(",");
        if (dotIndex !== -1) {
            filteredValue =
                filteredValue.substring(0, dotIndex + 1) + // İlk '.' ve öncesini al
                filteredValue
                    .substring(dotIndex + 1)
                    .replace(/\,/g, ""); // Sonraki tüm '.' karakterlerini kaldır
        }

        // .dan sonra maksimum 2 karakter sınırını uygula
        if (dotIndex !== -1 && filteredValue.length > dotIndex + 3) {
            filteredValue = filteredValue.substring(0, dotIndex + 3);
        }

        // Maksimum 12 karakter sınırını uygula
        if (filteredValue.length > 12) {
            filteredValue = filteredValue.slice(0, 12);
        }
        dispatch(setIncomeFormData({
            ...formData,
            [e.target.name]: filteredValue,
        }));
    };

    const handleSelectChange = async (field, selected) => {
        if (selected) {
            dispatch(setIncomeFormData({ ...formData, [field]: selected.value }));
        } else {
            dispatch(setIncomeFormData({ ...formData, [field]: null }));
        }
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setIncomeFormData({
            ...formData,
            ["phone"]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = async () => {
        console.log(formData);
        /*   if (!(formData.name) || formData.name === '') {
              toast.error(props.t("Please enter company name"));
              return;
          } */
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after failure
                    console.log("formmm");
                    console.log(formData);
                    await createIncome({ ...formData })
                        .then(async () => {
                            dispatch(closeModal()); // Close the modal after failure
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                            console.log(formData);
                            const updatedCompanies = await getIncomes(); // Fetch updated companies
                            dispatch(setIncomeList(updatedCompanies)); // Update Redux state
                            dispatch(clearIncomeFormData());
                            navigate(`/accounting/income`);
                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            //toast.error(props.t(error), { position: "top-right" });
                            customValidationError(error, props);
                        });
                }
            })
        );
    };

    const handleCreateDefinition = (inputValue, fieldName, defId) => {
        dispatch(
            openFastCreateModal({
                title: inputValue,
                onConfirm: async () => {
                    dispatch(closeFastCreateModal());
                    try {
                        const data = { name: inputValue, up_module_id: defId }
                        await createDefinition(data).then(async (newData) => {
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });

                            const updatedDatas = await getDefinitions(defId);
                            console.log("updatedDataaaa");
                            console.log(updatedDatas);
                            handleAccountingCase(defId, dataFormatter(updatedDatas));
                            dispatch(setIncomeFormData({ ...formData, [fieldName]: newData.id }));
                        })
                    } catch (error) {
                        console.log(error);
                        customValidationError(error, props);
                    }

                }
            }));
    }

    const handleCreateCurrent = (inputValue,) => {
        dispatch(
            openFastCreateModal({
                title: inputValue,
                onConfirm: async () => {
                    dispatch(closeFastCreateModal());
                    try {
                        await createCurrent({ name: inputValue }).then(async (newData) => {
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                            handleCurrents();
                            dispatch(setIncomeFormData({ ...formData, current_id: newData.id }));
                        })
                    } catch (error) {
                        console.log(error);
                        customValidationError(error, props);
                    }
                }
            }));
    }

    const handleAccountingCase = async (id, data) => {
        switch (id) {

            case ACCOUNTING.INCOME_ITEM:
                console.log("INCOME_ITEM")
                setIncomeItems(data);
                break;

            case ACCOUNTING.PAYMENT_METHOD:
                console.log("PAYMENT_METHOD")
                setPaymentMethods(data);
                break;

            case ACCOUNTING.CURRENCY:
                console.log("CURRENCY")
                setCurrencies(data);
                break;

            case ACCOUNTING.PAYMENT_STATUS:
                console.log("PAYMENT_STATUS")
                setPaymentStatuses(data)
                break;
            default:
                console.log("Default")
                console.error("Invalid accounting key:", id);
                break;
        }
    };

    return (
        <>
            <div style={{
                margin: "-40px -0px",
                position: "fixed",
                zIndex: 1000
            }}>
                <MyCompanyDropdown />
            </div>
            <Card>
                <CardBody>
                    <div>
                        <Form onSubmit={handleSubmit}>
                            <div className='d-flex justify-content-between mb-4'>
                                <h5 className="text-primary">{props.t("Add Income")}</h5>
                                <DynamicButton
                                    text={props.t("Save")}
                                    type="submit"
                                    buttonType={0}
                                />
                            </div>
                            <Row className='mb-4' form>
                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="income_item_id">{props.t("Income Item")}</Label>
                                        <DynamicCreatableSelect
                                            options={incomeItems}
                                            value={incomeItems?.find(data => data.value === formData.income_item_id || null)}
                                            onChange={(selected) => handleSelectChange("income_item_id", selected)}
                                            onCreateOption={async (inputValue) => { handleCreateDefinition(inputValue, "income_item_id", ACCOUNTING.INCOME_ITEM) }}
                                            placeholder={props.t("Select income")}
                                            required
                                            isClearable
                                            noOptionsMessage={() => props.t("no-option-message")}
                                            formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                            onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                            filterOption={(option, inputValue) => {
                                                const clientNo = option.data.label || "";
                                                const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                                return companyNameMatch;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="current_id">{props.t("Current")}</Label>
                                        <DynamicCreatableSelect
                                            options={currents}
                                            value={currents?.find(data => data.value === formData.current_id || null)}
                                            onChange={(selected) => handleSelectChange("current_id", selected)}
                                            onCreateOption={async (inputValue) => { handleCreateCurrent(inputValue) }}
                                            placeholder={props.t("Select current")}
                                            isClearable
                                            required
                                            noOptionsMessage={() => props.t("no-option-message")}
                                            formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                            onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                            filterOption={(option, inputValue) => {
                                                const clientNo = option.data.label || "";
                                                const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                                return companyNameMatch;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="payment_method_id">{props.t("Payment Method")}</Label>
                                        <DynamicCreatableSelect
                                            options={paymentMethods}
                                            value={paymentMethods?.find(data => data.value === formData.payment_method_id || null)}
                                            onChange={(selected) => handleSelectChange("payment_method_id", selected)}
                                            onCreateOption={async (inputValue) => { handleCreateDefinition(inputValue, "payment_method_id", ACCOUNTING.PAYMENT_METHOD) }}
                                            placeholder={props.t("Select payment method")}
                                            isClearable
                                            required
                                            noOptionsMessage={() => props.t("no-option-message")}
                                            formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                            onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                            filterOption={(option, inputValue) => {
                                                const clientNo = option.data.label || "";
                                                const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                                return companyNameMatch;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="quantity">{props.t("Quantity")}</Label>
                                        <DynamicSelect
                                            options={quantities}
                                            value={quantities?.find(data => data.value === formData.quantity || null)}
                                            onChange={(selected) => handleSelectChange("quantity", selected)}
                                            onCreateOption={async (inputValue) => { handleCreateDefinition(inputValue, "quantity", ACCOUNTING.PAYMENT_METHOD) }}
                                            placeholder={props.t("Select quantity")}
                                            isClearable
                                            required
                                            noOptionsMessage={() => props.t("no-option-message")}
                                            formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                            onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                            filterOption={(option, inputValue) => {
                                                const clientNo = option.data.label || "";
                                                const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                                return companyNameMatch;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="currency_id">{props.t("Currency")}</Label>
                                        <DynamicCreatableSelect
                                            options={currencies}
                                            value={currencies?.find(data => data.value === formData.currency_id || null)}
                                            onChange={(selected) => handleSelectChange("currency_id", selected)}
                                            onCreateOption={async (inputValue) => { handleCreateDefinition(inputValue, "currency_id", ACCOUNTING.CURRENCY) }}
                                            placeholder={props.t("Select currency")}
                                            isClearable
                                            required
                                            noOptionsMessage={() => props.t("no-option-message")}
                                            formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                            onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                            filterOption={(option, inputValue) => {
                                                const clientNo = option.data.label || "";
                                                const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                                return companyNameMatch;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="payment_status_id">{props.t("Payment Status")}</Label>
                                        <DynamicSelect
                                            options={paymentStatuses}
                                            value={paymentStatuses?.find(data => data.value === formData.payment_status_id || null)}
                                            onChange={(selected) => handleSelectChange("payment_status_id", selected)}
                                            onCreateOption={async (inputValue) => { handleCreateDefinition(inputValue, "payment_status_id", ACCOUNTING.PAYMENT_STATUS) }}
                                            placeholder={props.t("Select currency")}
                                            isClearable
                                            required
                                            noOptionsMessage={() => props.t("no-option-message")}
                                            formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                            onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                            filterOption={(option, inputValue) => {
                                                const clientNo = option.data.label || "";
                                                const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                                return companyNameMatch;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="price">{props.t("Price")}</Label>
                                        <DynamicInput
                                            type="text"
                                            name="price"
                                            required
                                            value={formData.price}
                                            onChange={handleChangePrice}
                                            placeholder={props.t("Enter price")}
                                            maxLength={48}
                                            minLength={1}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="booking_date">{props.t("Process Date")}</Label>
                                        <DynamicInput
                                            type="date"
                                            id="process_date"
                                            name="process_date"
                                            required
                                            value={formData.process_date}
                                            onChange={handleChange}
                                            onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={md} lg={lg} xl={xl}>
                                    <FormGroup>
                                        <Label for="payment_date">{props.t("Payment Date")}</Label>
                                        <DynamicInput
                                            type="date"
                                            id="payment_date"
                                            name="payment_date"
                                            required
                                            value={formData.payment_date}
                                            onChange={handleChange}
                                            onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={12}>
                                    <FormGroup>
                                        <Label for="price">{props.t("Description")}</Label>
                                        <DynamicInput
                                            type="text"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            placeholder={props.t("Enter description")}
                                            maxLength={48}
                                            minLength={6}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        <AcceptModal />
                        <FastCreateModal />
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

IncomeForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(IncomeForm)