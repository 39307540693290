import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { getLanguages } from "service/depot/languageService";
import usaFlag from 'assets/images/flags/abd.png';

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await getLanguages();
        const tempResponse = response.filter(data => data.is_passive === false);
        const langs = tempResponse.map((lang) => ({
          code: lang.isocode,
          label: lang.name,
          flag: lang.flag,
        }));
        setLanguages(langs);
        console.log("selectedLocalLang");
        const selectedLocalLang = localStorage.getItem("I18N_LANGUAGE");
        console.log("langs");
        console.log(langs);
        // Set the default language
        const currentLanguage = selectedLocalLang || langs[0].code;
        console.log(currentLanguage);
        setSelectedLang(currentLanguage);
        await i18n.changeLanguage(currentLanguage);
      } catch (error) {
        console.error("Error fetching languages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguageAction = async (lang) => {
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem("I18N_LANGUAGE", lang);
      setSelectedLang(lang);
    } catch (error) {
      console.error("Language switch error:", error);
    }
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-lg-inline-block ms-2">
        { (loading || languages.length < 1) ? 
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={usaFlag}
            height="16"
            className="me-2"
          />
          English
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle> 
        : 
        selectedLang === null || selectedLang === "en" || selectedLang === "bel" ?
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={usaFlag}
            height="16"
            className="me-2"
          />
          English
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle> 
        :
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={languages.find((l) => l.code === selectedLang)?.flag}
            height="16"
            className="me-2"
          />
          {languages.find((l) => l.code === selectedLang)?.label}
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>}
        {languages.length > 0 ? <DropdownMenu className="language-switch dropdown-menu-end">
          {loading ? <DropdownItem
            key="ENG"
            onClick={() => { }}
          >
            <img
              src={usaFlag}
              className="me-1"
              height="12"
            />
            <span className="align-middle">English</span>

          </DropdownItem>

            : languages.map((lang) => (
              <DropdownItem
                key={lang.code}
                onClick={() => changeLanguageAction(lang.code)}
                className={`notify-item ${selectedLang === lang.code ? "active" : ""
                  }`}
              >
                <img
                  src={lang.flag}
                  className="me-1"
                  height="12"
                />
                <span className="align-middle">{lang.label}</span>
              </DropdownItem>
            ))}

        </DropdownMenu>: null}
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
