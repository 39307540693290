import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Row, Col, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';

import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from 'components/Common/Modals/acceptModal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import { createCountry, getCountries } from 'service/Definitions/countryService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { getCities } from 'service/Definitions/cityService';
import UpdateCityForm from 'pages/Depot/Definitions/City/cityUpdateForm';
import { openUpdateModal } from 'store/updateModal/actions';
import { clearCityFormData, setCityFormData } from 'store/updates/cityForm/action';
import { setIsCreate } from 'store/isUpdate/action';
import { clearSelectedCity, setSelectedCity } from 'store/depot/selectedCity/action';
import { setCountries } from 'store/depot/Definitions/country/action';
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import DynamicButton from 'components/Common/Button/dynamicButton';
import { setCities } from 'store/depot/Definitions/city/action';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { customValidationError } from 'components/Common/customValidationError';
import { createCompanyVisit as createCompanyVisit, getCompanyVisits as getCompanyVisits } from 'service/visiting/companyVisitService';
import { setCompanyVisitFormData as setCompanyVisitFormData } from 'store/visiting/companyVisitForm/action';
import { setCompanyVisits as setCompanyVisits } from 'store/visiting/companyVisitList/action';

const CompanyVisitForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const formData = useSelector((state) => state.companyVisitFormData?.formData || null);
    const countries = useSelector((state) => state.countries?.countries || []);
    const cities = useSelector((state) => state.cities?.cities || []);
    const md = 6;
    const lg = 6;
    const xl = 4;


    const getCountriesAndCities = async () => {
        try {
            const countryList = await getCountries();
            const cityList = await getCities();
            const formattedCities = cityList.map(city => ({
                value: city.id,
                label: city.name,
                country_id: city.country_id
            }));
            const formattedCountries = countryList.map(country => ({
                value: country.id,
                label: country.name
            }));
            console.log("**********");
            console.log(formattedCities);
            console.log(formattedCountries);
            dispatch(setCountries(formattedCountries));
            dispatch(setCities(formattedCities));
        } catch (error) {
            console.log("error");
        }
    };

    useEffect(() => {
        getCountriesAndCities();
    }, [dispatch]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setCompanyVisitFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handleChangeLowerCase = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        dispatch(setCompanyVisitFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setCompanyVisitFormData({
            ...formData,
            ["contactPhone"]: value,
        }));
    };

    const handleGsm = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setCompanyVisitFormData({
            ...formData,
            ["contactGsm"]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleSelectCountryChange = async (field, selected) => {
        dispatch(clearSelectedCity());
        dispatch(setCompanyVisitFormData({ ...formData, country_id: null, city_id: null }));
        console.log(selected);
        if (selected) {
            dispatch(setCompanyVisitFormData({ ...formData, [field]: selected.value, city_id: null }));
            await getCountriesAndCities();
        } else {
            dispatch(setCompanyVisitFormData({ ...formData, [field]: null, city_id: null }));
            await getCountriesAndCities();
        }
    };

    const handleSelectCityChange = (field, selected) => {
        dispatch(setSelectedCity(selected));
        dispatch(setCompanyVisitFormData({ ...formData, [field]: selected?.value }));
    };

    const handleCreateCountry = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        dispatch(
            openFastCreateModal({
                title: inputValue,
                body: <span>{props.t("modal-save-body")}</span>,
                onConfirm: async () => {
                    dispatch(closeFastCreateModal());
                    const newCountry = {
                        name: inputValue,
                    };
                    await createCountry(newCountry)
                        .then(async (addedCountry) => {
                            console.log("idddssssss");
                            console.log(addedCountry);
                            const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                            const newSelected = newCountryList.find(data => data.id === addedCountry.id);
                            const formattedDatas = newCountryList.map(country => ({
                                value: country.id,
                                label: country.name
                            }));
                            dispatch(setCountries(formattedDatas));
                            dispatch(clearSelectedCity());
                            dispatch(setCompanyVisitFormData({ ...formData, ["country_id"]: newSelected.id, ["city_id"]: null }));
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        })
                        .catch((error) => {
                            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                            customValidationError(error, props);

                        });
                }
            }));
    }

    const handleCreateCity = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        handleEdit(inputValue);
        dispatch(
            openUpdateModal({
                body: <UpdateCityForm page={"company"} />,
                onConfirm: async () => { }
            })
        );
    }

    // Handle editing row and open modal
    const handleEdit = async (inputValue) => {
        dispatch(setIsCreate(true));
        dispatch(setCityFormData({ country_id: formData.country_id, name: inputValue }));
    };

    const handleConfirm = async () => {
        console.log(formData);
        /*         if (!(formData.name) || formData.name === '') {
                    toast.error(props.t("Please enter designation"));
                    return;
                } */
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after failure
                    await createCompanyVisit(formData)
                        .then(async () => {
                            dispatch(closeModal()); // Close the modal after failure
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                            console.log(formData);
                            const updatedDatas = await getCompanyVisits(); // Fetch updated datas
                            dispatch(setCompanyVisits(updatedDatas)); // Update Redux state
                            dispatch(clearCityFormData());
                            dispatch(clearSelectedCity());
                            setSelectedCity(null);
                            dispatch(setCompanyVisitFormData({}));
                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            //toast.error(props.t(error), { position: "top-right" });
                            customValidationError(error, props);
                        });
                }
            })
        );


    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Add Company Visit")}</h5>
                    <DynamicButton
                        text={props.t("Save")}
                        type="submit"
                        buttonType={0}
                    />
                </div>
                <Row className='mb-4' form>


                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="documentNumber">{props.t("Document Number")}</Label>
                            <DynamicInput
                                type="text"
                                name="documentNumber"
                                id="documentNumber"
                                value={formData.documentNumber}
                                onChange={handleChange}
                                placeholder={props.t("Enter document number")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="appointmentBy">{props.t("Appointment By")}</Label>
                            <DynamicInput
                                type="text"
                                name="appointmentBy"
                                id="appointmentBy"
                                value={formData.appointmentBy}
                                onChange={handleChange}
                                placeholder={props.t("Enter appointment by")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="appointmentDate">{props.t("Appointment Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="appointmentDate"
                                name="appointmentDate"
                                value={formData.appointmentDate}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="previousEvaluationDate">{props.t("Previouse Evaluation Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="previousEvaluationDate"
                                name="previousEvaluationDate"
                                value={formData.previousEvaluationDate}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="location">{props.t("Location")}</Label>
                            <DynamicInput
                                type="text"
                                name="location"
                                id="location"
                                value={formData.location}
                                onChange={handleChange}
                                placeholder={props.t("Enter location")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="visitDate">{props.t("Visit Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="visitDate"
                                name="visitDate"
                                value={formData.visitDate}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="visitorName">{props.t("Visitor Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="visitorName"
                                id="visitorName"
                                value={formData.visitorName}
                                onChange={handleChange}
                                placeholder={props.t("Enter visitor name")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="contactName">{props.t("Contact Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactName"
                                id="contactName"
                                value={formData.contactName}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact name")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="contactPosition">{props.t("Contact Position")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactPosition"
                                id="contactPosition"
                                value={formData.contactPosition}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact position")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="contactCompanyName">{props.t("Contact Company Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactCompanyName"
                                id="contactCompanyName"
                                value={formData.contactCompanyName}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact company name")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="contactPhone">{props.t("Contact Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter contact phone number")}
                                value={formData.contactPhone}
                                minLength={6}
                                maxLength={24}
                                onChange={value => handlePhone(value)} />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="contactGsm">{props.t("Contact GSM")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter contact GSM number")}
                                value={formData.contactGsm}
                                minLength={6}
                                maxLength={24}
                                onChange={value => handleGsm(value)} />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="contactEmail">{props.t("Contact Email")}</Label>
                            <DynamicInput
                                type="email"
                                name="contactEmail"
                                id="contactEmail"
                                value={formData.contactEmail}
                                onChange={handleChangeLowerCase}
                                placeholder={props.t("Enter contact email address")}
                                minLength={3}
                                maxLength={64}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="contactAddress">{props.t("Contact Address")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactAddress"
                                id="contactAddress"
                                value={formData.contactAddress}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact address")}
                                maxLength={255}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="sectorInfo">{props.t("Sector Info")}</Label>
                            <DynamicInput
                                type="text"
                                name="sectorInfo"
                                id="sectorInfo"
                                value={formData.sectorInfo}
                                onChange={handleChange}
                                placeholder={props.t("Enter sector info")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="marketStatus">{props.t("Market Status")}</Label>
                            <DynamicInput
                                type="text"
                                name="marketStatus"
                                id="marketStatus"
                                value={formData.marketStatus}
                                onChange={handleChange}
                                placeholder={props.t("Enter market status")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    {/*                     <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="exportCountries">{props.t("Export Countries")}</Label>
                            <DynamicCreatableSelect
                                options={countries}
                                value={countries.find(data => data.value === formData.exportCountries || null)}
                                onChange={(selected) => handleSelectCountryChange("exportCountries", selected)}
                                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                                placeholder={props.t("Enter export countries")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col> */}



                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="exportCountries">{props.t("Export Countries")}</Label>
                            <DynamicInput
                                type="text"
                                name="exportCountries"
                                id="exportCountries"
                                value={formData.exportCountries}
                                onChange={handleChange}
                                placeholder={props.t("Enter export countries")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="importStatus">{props.t("Import Status")}</Label>
                            <DynamicInput
                                type="text"
                                name="importStatus"
                                id="importStatus"
                                value={formData.importStatus}
                                onChange={handleChange}
                                placeholder={props.t("Enter import status")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="cargoDemand">{props.t("Cargo Demand")}</Label>
                            <DynamicInput
                                type="text"
                                name="cargoDemand"
                                id="cargoDemand"
                                value={formData.cargoDemand}
                                onChange={handleChange}
                                placeholder={props.t("Enter cargo demand")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="nextMeetingDate">{props.t("Next Meeting Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="nextMeetingDate"
                                name="nextMeetingDate"
                                value={formData.nextMeetingDate}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="evaluation">{props.t("Evaluation")}</Label>
                            <DynamicInput
                                type="text"
                                name="evaluation"
                                id="evaluation"
                                value={formData.evaluation}
                                onChange={handleChange}
                                placeholder={props.t("Enter evaluation")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>



                </Row>
            </Form>
            <AcceptModal />
            <FastCreateModal />
        </div>
    );
};

CompanyVisitForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(CompanyVisitForm)