import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "store/actions";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { Card } from "reactstrap";
import UpdateHevelenForm from "./updateHevelenForm";

const UpdateHevelenPage = (props) => {
    const { t } = useTranslation();
    document.title = t("Uitslug Update");
    const breadcrumbItems = [

    ];
    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);

    return (
        <React.Fragment>
            <UpdateHevelenForm />
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(UpdateHevelenPage);
