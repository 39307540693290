import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { getMyCompanies } from "service/accounting/myCompanyService";
import PropTypes from "prop-types";
import "styles/custom/my-company-dropdown.css"; // Özel CSS dosyası
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MyCompanyDropdown = (props) => {
  const [selectedMyCompany, setSelectedMyCompany] = useState("");
  const myCompanies = useSelector((state) => state.myCompanies?.list || []);
  const [menu, setMenu] = useState(false);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchData = async () => {

      try {
        if (myCompanies && myCompanies.length > 0) {
          console.log("data geldiiiiii");
          const filteredCompanies = myCompanies?.filter((data) => !data.is_deleted);
          const formattedData = filteredCompanies.map((data) => ({
            value: data.id,
            label: data.name,
          }));
          console.log("formatted myCompanies", formattedData);

          if (formattedData.length > 0) {
            setDatas(formattedData);
            const storedCompanyId = localStorage.getItem("my_company_id");
            const defaultCompanyId = storedCompanyId || formattedData[0].value;
            localStorage.setItem("my_company_id", defaultCompanyId);
            setSelectedMyCompany(defaultCompanyId);
          }
        } else {
          const response = await getMyCompanies();
          console.log("Response companies:", response);

          const filteredCompanies = response?.filter((data) => !data.is_deleted);
          const formattedData = filteredCompanies.map((data) => ({
            value: data.id,
            label: data.name,
          }));
          console.log("formatted myCompanies", formattedData);

          if (formattedData.length > 0) {
            setDatas(formattedData);
            const storedCompanyId = localStorage.getItem("my_company_id");
            const defaultCompanyId = storedCompanyId || formattedData[0].value;
            localStorage.setItem("my_company_id", defaultCompanyId);
            setSelectedMyCompany(defaultCompanyId);
          }
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const changeMyCompanyAction = (companyId) => {
    localStorage.setItem("my_company_id", companyId);
    setSelectedMyCompany(companyId);
    window.location.reload();
  };

  const toggle = () => setMenu(!menu);

  return (
    loading || !datas.length ? null :
      <Dropdown isOpen={menu} toggle={toggle} className="d-lg-inline-block">
        {/* Dropdown Toggle */}
        <DropdownToggle
          style={{ padding: "0px !important" }}
          className="dropdown-toggle-custom"
          tag="button"
        >
          {loading || !datas.length
            ? <span className="d-flex align-items-center text-center" style={{ fontSize: "10px" }}>{props.t("Select company")}</span>
            : <span className="d-flex align-items-center text-center" style={{ fontSize: "10px" }}>{datas.find((data) => data.value == selectedMyCompany)?.label || props.t("Select Company")}</span>
          }
        </DropdownToggle>

        {/* Dropdown Menu */}
        <DropdownMenu className="align-items-center">
          {loading || datas.length === 0 ? (
            <DropdownItem className="text-muted text-center">
              <Link to="/accounting/my-company/create">
                <span className="align-middle">
                  {props.t("No Companies Available")}
                </span>
              </Link>
            </DropdownItem>
          ) : (
            datas.map((item) => (
              <DropdownItem
                key={item.value}
                onClick={() => changeMyCompanyAction(item.value)}
                className={`notify-item dropdown-item-custom ${selectedMyCompany === item.value ? "active-item" : ""
                  }`}
              >
                <i className="mdi mdi-office-building me-2"></i>
                <span className="align-middle">{item.label}</span>
              </DropdownItem>
            ))
          )}
        </DropdownMenu>
      </Dropdown>
  );
};

MyCompanyDropdown.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(MyCompanyDropdown);
