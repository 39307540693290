import { SET_OUTCOME_FORM_DATA, CLEAR_OUTCOME_FORM_DATA } from './actionTypes';

export const setOutcomeFormData = (formData) => ({
  type: SET_OUTCOME_FORM_DATA,
  payload: formData,
});

export const clearOutcomeFormData = () => ({
  type: CLEAR_OUTCOME_FORM_DATA,
});
