import React from "react";
import 'styles/custom/custom-print-style.css'
import logoSrc from "assets/images/act-logo.webp";
import DynamicQr from "../Qr/dynamicQr";
import SplitDataTable from "../SplitDataTable/splitDataTable";
import customStyles from 'styles/customDatatableStyle';
import DataTable from "react-data-table-component";

const DynamicPrintCompanyVisit = ({
  contentRef,
  dataObject = {},
  datas = [],
  datas2 = [],
  datas3 = [],
  dataColumns = [],
  itemDatas = [],
  itemColumns = [],
  actionTitle = "",
  actionTitle2 = "",
  actionTitle3 = "",
  actionTitleBgColor = "#7a6fbe",

  t = (key) => key, // Çeviri fonksiyonu (varsayılan olarak sadece key döner)
}) => {
  const today = () => {
    const today = new Date();
    const formattedDate = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
    return formattedDate;
  }
  console.log(datas2);
  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  const customItemStyles = {
    header: {
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: '#FF9C73',
        color: '#333',
      },
    },
    headCells: {
      style: {
        backgroundColor: '#FF9C73',
        border: '1px solid #ddd',
        textAlign: 'left',
        padding: '8px',
      },
    },
    cells: {
      style: {
        border: '1px solid #ddd',
        padding: '8px',
        fontFamily: 'Arial, sans-serif',
      },
    },
    rows: {
      style: {
        borderBottom: '1px solid #ddd',
      },
    },
  };

  const filteredItemColumns = itemColumns.filter(
    (column) => column.name !== t("Actions")
  );

  return (
    <div className="print-only">



      <div ref={contentRef} style={{ margin: "40px", fontFamily: "Arial, sans-serif" }}>
        {/* Header */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: "32px",
          }}
        >
          <div>
            <img
              src={logoSrc}
              alt="Company Logo"
              style={{ width: "260px", height: "auto", marginBottom: "10px" }}
            />
            <div style={{ paddingLeft: "12px", fontSize: "14px", fontWeight: "bold", marginBottom: "12px" }}>
              Antwerp Container Transport
            </div>


            <div style={{ paddingLeft: "12px", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>
              Fevzi Çakmak Mh. 10632 Sk.
            </div>
            <div style={{ paddingLeft: "12px", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>
              My Plaza İş Merkezi 2C Giriş No: 201
            </div>
            <div style={{ paddingLeft: "12px", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>
              Karatay/KONYA
            </div>
            <div style={{ paddingLeft: "12px", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>
              +90 332 323 23 34
            </div>
          </div>



          <div className="text-end">
            <DynamicQr size={140} value={datas2[0]?.value} />
            <div>{datas2[0]?.value}</div>
            <div style={{ paddingLeft: "12px", fontSize: "12px" }}>{today()}</div>

          </div>





        </div>






        {/* Title */}
        {actionTitle2 !== "" ?
          <h5 style={{ backgroundColor: actionTitleBgColor, margin: 0, padding: 0 }} className="py-2 text-center text-white font-weight-bold">
            {actionTitle2}
          </h5>
          : null}

        <div style={{ gap: "20px", marginBottom: "48px" }}>
          <SplitDataTable
            data={datas2}
            columns={dataColumns}
            customStyles={customStyles}
            t={t}
          />
        </div>






        {/* Title */}
        {actionTitle !== "" ?
          <h5 style={{ backgroundColor: actionTitleBgColor }} className="py-2 text-center text-white font-weight-bold">
            {actionTitle}
          </h5>
          : null}

        {/* Split Data Table */}
        <div style={{ gap: "20px", marginBottom: "48px" }}>
          <SplitDataTable
            data={datas}
            columns={dataColumns}
            customStyles={customStyles}
            t={t}
          />
        </div>



        {/* Title */}
        {actionTitle3 !== "" ?
          <h5 style={{ backgroundColor: actionTitleBgColor }} className="py-2 text-center text-white font-weight-bold">
            {actionTitle3}
          </h5>
          : null}

        <div style={{ gap: "20px", marginBottom: "48px" }}>
          <SplitDataTable
            data={datas3}
            columns={dataColumns}
            customStyles={customStyles}
            t={t}
          />
        </div>



        {/* <DataTable
          columns={itemColumns}
          data={itemDatas}
          customStyles={customItemStyles}
          highlightOnHover
          noHeader
        /> */}

        {/*         <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#FF9C73", fontWeight: "bold" }}>
              {filteredItemColumns.map((column, index) => (
                <th
                  key={index}
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {t(column.name)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {itemDatas.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                style={{
                  borderBottom: "1px solid #ddd",
                  backgroundColor: rowIndex % 2 === 0 ? "#f9f9f9" : "#fff",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f1f1f1")}
                onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor =
                  rowIndex % 2 === 0 ? "#f9f9f9" : "#fff")
                }
              >
                {filteredItemColumns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    {row[column.finder]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table> */}


        {/* <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#FF9C73" }}>
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{t("Product Name")}</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{t("Extern Party No")}</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{t("Container No")}</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{t("Description")}</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{t("Leverage Charge")}</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{t("Quantity")}</th>
            </tr>
          </thead>
          <tbody>
            {itemData.map((row, index) => (
              <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.product_name}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.extern_party_no}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.container_no}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.description}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.leverage_charge}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.count}</td>
              </tr>
            ))}
          </tbody>
        </table> */}
      </div>
    </div >
  );
};

export default DynamicPrintCompanyVisit;
