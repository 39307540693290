import { SET_OUTCOME_LIST, CLEAR_OUTCOME_LIST } from './actionTypes';

const initialState = {
  list: [],
};

const outcomeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OUTCOME_LIST:
      return {
        ...state,
        list: action.payload,
      };

    case CLEAR_OUTCOME_LIST:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};

export default outcomeListReducer;
