import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { getCompanies } from 'service/depot/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { openModal, closeModal } from 'store/modal/actions';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import DynamicButton from 'components/Common/Button/dynamicButton';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import HevelenItemList from './hevelenItemList';
import { createHeleven, getHelevensByCompany } from 'service/depot/helevenService';
import { setHelevenFormData } from 'store/depot/helevenForm/action';
import { clearHelevenItemList, setHelevenItemList } from 'store/depot/helevenItemList/action';

const HevelenForm = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const helevenFormData = useSelector((state) => state.helevenFormData?.formData);
  const helevenItemList = useSelector((state) => state.helevenItemList?.list || []);
  const [companies, setCompanies] = useState([]);

  const md = 12;
  const lg = 6;
  const xl = 6;

  useEffect(() => {
    dispatch(clearHelevenItemList());
    dispatch(setHelevenFormData({ ...helevenFormData, source_company_no: null }));
    const fetchData = async () => {
      try {
        //const uitslug = await getUitslug(id);
        const companies = await getCompanies();
        setCompanies(dataFormatter(companies));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    console.log("heleven");
    console.log(helevenFormData);
  }, []);

  const dataFormatter = (list) => {
    const formattedList = list?.map(data => ({
      value: data.client_no,
      label: data.name
    }));
    return formattedList;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setHelevenFormData({ ...helevenFormData, [name]: value != "" ? value.toLocaleUpperCase("tr") : null }));
  };

  useEffect(() => {
    console.log("uitslugFormData");
    console.log(helevenFormData);
  }, [helevenFormData]);

  const handleSelectChange = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    console.log("value : " + value);
    dispatch(setHelevenFormData({ ...helevenFormData, [field]: value }));
  };

  const handleChangeMainCompany = async (field, selectedOption) => {
    if (selectedOption) {
      try {
        const value = selectedOption.value;
        dispatch(setHelevenFormData({ ...helevenFormData, destination_company_no: null, [field]: value }));
        const dataByCompany = await getHelevensByCompany(value);
        console.log("uitslug listesi");
        console.log(dataByCompany);
        dispatch(setHelevenItemList(dataByCompany));
      } catch (error) {
        console.log(error)
      }
    } else {
      dispatch(setHelevenFormData({ ...helevenFormData, destination_company_no: null,  [field]: null }));
      dispatch(clearHelevenItemList());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleConfirm();
  }

  function handleItems(inputArray) {
    console.log("liste gelmesi lazım");
    console.log(inputArray);
    if (!Array.isArray(inputArray)) {
      console.log("Input is not an array");
      return [];
    }

    // Yeni dizi oluştur
    const newArray = inputArray
      .filter(item => item.output_count)
      .map(item => ({
        inslug_item_id: item.inslug_item_id,
        inslug_id: item.inslug_id,
        output_count: parseInt(item.output_count, 10),
      }));
    console.log("-----");
    console.log(newArray);

    return newArray;
  }


  const handleConfirm = async (id) => {
    const items = handleItems(helevenItemList);
    const finalData = { hevelen: helevenFormData, hevelenItems: items };
    console.log("finalData");
    console.log(finalData);
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after failure
          await createHeleven(finalData).then(() => {
            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            navigate(`/hevelens`);
          })
            .catch((error) => {
              console.error("ekelme işlemi başarısız oldu:", error);
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            });
        }
      })
    );
  }

  return (
    <Form onSubmit={handleSubmit}>

      <Card className='p-4 rounded-lg'>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("New Heleven")}</h5>
          <DynamicButton
            text={props.t("Save")}
            type="submit"
            buttonType={0}
          />
        </div>

        <Row form>
          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="source_company_no">{props.t("Source Company Name")}</Label>
              <DynamicSelect
                options={companies}
                value={companies.find((data) => data.value === helevenFormData.source_company_no)}
                onChange={(selected) => {
                  handleChangeMainCompany('source_company_no', selected);
                }}
                placeholder={props.t("Select source company")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const valueMatch = option.value?.toLowerCase().includes(inputValue.toLowerCase());
                  const labelMatch = option.label?.toLowerCase().includes(inputValue.toLowerCase());
                  return valueMatch || labelMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="destination_company_no">{props.t("Destination Company")}</Label>
              <DynamicCreatableSelect
                options={companies.filter(data => data.value != helevenFormData.source_company_no)}
                value={companies.find((data) => data.value === helevenFormData.destination_company_no)}
                onChange={(selected) => {
                  handleSelectChange('destination_company_no', selected);
                }}
                //onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue) }}
                placeholder={props.t("Select destination company")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add a new")}: ${inputValue}`}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="booking_date">{props.t("Booking Date")}</Label>
              <DynamicInput
                type="date"
                id="booking_date"
                name="booking_date"
                value={helevenFormData.booking_date}
                onChange={handleInputChange}
                onClick={(e) => e.target.showPicker()}

              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="extern_document_no">{props.t("Extern Document No")}</Label>
              <DynamicInput
                type="text"
                id="extern_document_no"
                name="extern_document_no"
                value={helevenFormData.extern_document_no}
                placeholder={props.t("Enter extern document no")}
                onChange={handleInputChange}
                minLength={3}
                maxLength={255}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label for="description">{props.t("Description")}</Label>
              <DynamicInput
                type="text"
                id="description"
                name="description"
                value={helevenFormData.description}
                placeholder={props.t("Enter description")}
                onChange={handleInputChange}
                minLength={3}
                maxLength={255}
              />
            </FormGroup>
          </Col>
        </Row>

        <AcceptModal />
        <FastCreateModal />
      </Card>
      <HevelenItemList />
    </Form >
  );
};

HevelenForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(HevelenForm)