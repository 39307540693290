// src/store/selectedCountry/selectedCountryActions.js
import { SET_OUTCOME_LIST, CLEAR_OUTCOME_LIST, UPDATE_UITSLUG_ITEM } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setOutcomeList = (data) => ({
  type: SET_OUTCOME_LIST,
  payload: data,
});

// Seçilen ülkeyi temizlemek
export const clearOutcomeList = () => ({
  type: CLEAR_OUTCOME_LIST,
});


