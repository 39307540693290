import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Input } from 'reactstrap';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import customStyles from 'styles/customDatatableStyle';
import { useNavigate, useParams } from 'react-router-dom';
import qr from "assets/icons/qr-colored.png";
import { useReactToPrint } from 'react-to-print';
import 'styles/custom/custom-print-style.css'
import DetailsButton from 'components/Common/Button/detailsButton';
import { closeModal, openModal } from 'store/modal/actions';
import { toast } from 'react-toastify';
import { hasUpdatePermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import AcceptModal from 'components/Common/Modals/acceptModal';
import SplitDataTable from 'components/Common/SplitDataTable/splitDataTable';
import DynamicPrint from 'components/Common/Print/dynamicPrint';
import { approveHeleven, getHeleven } from 'service/depot/helevenService';

const today = () => {
  const today = new Date();
  const formattedDate = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
  return formattedDate;
}

const HevelenDetails = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const { id } = useParams();
  const [hevelenData, setHevelenData] = useState([]);
  const [hevelenObject, setHevelenObject] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [itemSearch, setItemSearch] = useState('');
  const [filteredItemData, setFilteredItemData] = useState([]);

  const contentRef = useRef(null);


  // Tarih olup olmadığını kontrol eden ve uygun formatta döndüren fonksiyon
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString; // Geçerli bir tarih değilse olduğu gibi döndür
    }

    // Gün ay yıl saat dakika formatında döndür
    return date.toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const fetchHevelenData = async () => {
    try {
      const hevelenInfo = await getHeleven(id);
      console.log("inslugInfooooss");
      console.log(hevelenInfo);
      const { hevelen, items } = hevelenInfo;

      const hevelenArray = [
        { key: "Source Company Name", value: hevelen.source_company_name },
        { key: "Destination Company Name", value: hevelen.destination_company_name },
        { key: "Extern Document No", value: hevelen.extern_document_no },
        { key: "Booking Date", value: hevelen.booking_date ? formatDate(hevelen.booking_date.toString()) : "" },
        { key: "Description", value: hevelen.description },
        {
          key: "Process Status", value: hevelen.process_status_id !== undefined && (
            <span
              className="d-flex align-items-center justify-content-center text-center"
              style={{
                fontSize: "9px",
                height: "16px",
                marginTop: "2px",
                marginLeft: "8px",
                padding: "2px 12px 0px 12px", // Daha az padding
                borderRadius: "12px", // Oval kenarlar
                backgroundColor:
                  hevelen.process_status_id % 4 === 0
                    ? "#dc3545" // danger (kırmızı)
                    : hevelen.process_status_id % 4 === 1
                      ? "#ffc107" // warning (sarı)
                      : hevelen.process_status_id % 4 === 2
                        ? "#28a745" // success (yeşil)
                        : "#17a2b8", // info (mavi)
                textAlign: "center",
                color: "white"
              }}
            >
              {hevelen.process_status_name}
            </span>)
        }
      ];

      setHevelenObject(hevelen);
      setHevelenData(hevelenArray);
      setItemData(items);
      setFilteredItemData(items);
    } catch (error) {
      console.error("Error fetching inslug data:", error);
    }
  };

  useEffect(() => {
    fetchHevelenData();
  }, [dispatch]);

  useEffect(() => {
    setFilteredItemData(
      itemData.filter(row =>
        row.product_name?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.extern_party_no?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.container_no?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.output_count?.toString().toLowerCase().includes(itemSearch.toLowerCase())
      )
    );
  }, [itemSearch, itemData]);

  const hevelenColumns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold',
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const itemColumns = [
    {
      name: props.t("Product Name"),
      selector: row => row.product_name,
      finder: "product_name",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Extern Party No"),
      selector: row => row.extern_party_no,
      finder: "extern_party_no",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Container No"),
      selector: row => row.container_no,
      finder: "container_no",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Recieved Quantity"),
      selector: row => row.output_count,
      finder: "output_count",
      sortable: true,
      wrap: true,
    },
/*     {
      name: props.t("Actions"),
      selector: row => <DetailsButton disabled={false} onClick={() => handleViewItemDetails(row.id)} />,
      finder: "Actions",
      sortable: true,
      wrap: true,
    }, */
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  const handleViewItemDetails = (id) => {
    if (hevelenObject.process_status_id === 13) {
      toast.error(props.t("Approve uitslug first"));
      return;
    }
    navigate(`/uitslug/item-qr/${id}`);
  };

  const handleFinish = () => {
    if (filteredItemData.length < 1) {
      toast.error(props.t("Add an item first"));
      return;
    }
    dispatch(
      openModal({
        title: props.t("Finish Hevelen"),
        body: props.t("This action is irreversible and cannot be modified. Are you sure?"),
        titleColor: "red",
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await approveHeleven(id); // Delete company
            toast.success(props.t("toast-success-approve-data"), { position: "top-right" });
            fetchHevelenData();
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-approve-data"), { position: "top-right" });
          }
        }
      })
    );
  }

  const handlePrint = useReactToPrint({ contentRef });

  const handlePrintHevelenInfo = () => {
/*     if (hevelenObject.process_status_id === 13) {
      toast.error(props.t("Approve uitslug first"));
      return;
    } */
    handlePrint();
  }

  const navigateUpdateScreen = () => {
    navigate(`/hevelen/update/${hevelenObject.client_no}/${hevelenObject.id}`);
  }

  return (
    <div>
      <Card className="p-4 rounded-lg mb-4">
        {hevelenObject.process_status_id === 13 ? <h6 className="glowing-text">{props.t("Not Finished")}</h6> : null}
        <div className="d-flex justify-content-between mb-2">

          <div className='d-flex'>
            <h5 className="text-primary">{props.t("Hevelen Information")}</h5>
            {hevelenObject.process_status_id !== undefined && (
              <span
                className="d-flex align-items-center justify-content-center text-center"
                style={{
                  fontSize: "9px",
                  height: "16px",
                  marginTop: "2px",
                  marginLeft: "8px",
                  padding: "2px 12px 0px 12px", // Daha az padding
                  borderRadius: "12px", // Oval kenarlar
                  backgroundColor:
                    hevelenObject.process_status_id % 4 === 0
                      ? "#dc3545" // danger (kırmızı)
                      : hevelenObject.process_status_id % 4 === 1
                        ? "#ffc107" // warning (sarı)
                        : hevelenObject.process_status_id % 4 === 2
                          ? "#28a745" // success (yeşil)
                          : "#17a2b8", // info (mavi)
                  textAlign: "center",
                  color: "white"
                }}
              >
                {hevelenObject.process_status_name}
              </span>
            )}
          </div>
          <div className='d-flex'>
            <Button className="m-0 p-0 px-2" color="red" onClick={handlePrintHevelenInfo}>
              <img src={qr} width={'30'} alt="" />
            </Button>
            {hevelenObject.process_status_id === 13 && hasUpdatePermission(permissions, "DE_04") ? <Button className="mx-3 glowing-button-red" color="danger" onClick={handleFinish}>{props.t("Finish")}</Button> : null}
            {(hevelenObject.process_status_id === 13 || hevelenObject.process_status_id === 14) && hasUpdatePermission(permissions, "DE_04") ?
              <Button color="warning" onClick={navigateUpdateScreen}>{props.t("Update")}</Button> : null}
          </div>
        </div>
        <SplitDataTable
          data={hevelenData}
          columns={hevelenColumns}
          t={props.t} />

      </Card>

      <Card className="p-4 rounded-lg">
        <h5 className="text-primary">{props.t("Product Information")}</h5>
        <DataTable
          columns={itemColumns}
          data={filteredItemData}
          customStyles={customStyles}
          highlightOnHover
          pagination
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          subHeader
          subHeaderComponent={
            <div className='d-flex justify-content-end w-50'>
              {/* <Button className="m-0 p-0 px-3" color="red" onClick={() => { handlePrintType(id) }}>
                <img src={qr} width={'30'} alt="" />
              </Button> */}
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={itemSearch}
                onChange={(e) => setItemSearch(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
                maxLength={32}
              />
            </div>
          }
        />
      </Card>


      <DynamicPrint
        t={props.t}
        contentRef={contentRef}
        datas={hevelenData}
        dataColumns={hevelenColumns}
        dataObject={hevelenObject}
        itemDatas={itemData}
        itemColumns={itemColumns}
        actionTitle={props.t('Hevelen')}
        actionTitleBgColor="#58db83"
      />
      <AcceptModal />
    </div>
  );
};

HevelenDetails.propTypes = {
  match: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(HevelenDetails);
