import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getCompany, getCompanyVisitDetails } from 'service/visiting/companyVisitService'; // API functions
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasCreatePermission, hasExcelPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import 'styles/custom/datatable.css'
import { useNavigate, useParams } from 'react-router-dom';
import SplitDataTable from 'components/Common/SplitDataTable/splitDataTable';
import { setCreateClientProductFormData } from 'store/depot/createClientProductForm/action';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import DynamicPrintCompanyVisit from 'components/Common/Print/dynamicPrintCompanyVisit';
import qr from "assets/icons/qr-colored.png";
import { FaPrint } from 'react-icons/fa'; // React Icons'dan bir ikon import edin




const CompanyVisitDetails = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchTextInfo, setSearchTextInfo] = useState(''); // State for search text
  const [searchTextData, setSearchTextData] = useState(''); // State for search text
  const [dataList, setDataList] = useState([]);
  const [infoList, setInfoList] = useState([]);
  const [contactArray, setContactArray] = useState([]);
  const [visitArray, setVisitArray] = useState([]);
  const [meetArray, setMeetArray] = useState([]);

  const contentRef = useRef(null);

  const [filteredDataList, setFilteredDataList] = useState([]);
  const [filteredInfoList, setFilteredInfoList] = useState([]);
  const [companyVisitObject, setCompanyVisitObject] = useState([]);
  const [inslugData, setInslugData] = useState([]);
  const [inslugObject, setInslugObject] = useState([]);
  const [itemData, setItemData] = useState([]);

  const inslugColumns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];
  const itemColumns = [
    {
      name: props.t("Contaaaact Name"),
      selector: row => row.contactName,
      finder: "contactName",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Contact Position"),
      selector: row => row.contactPosition,
      sortable: true,
      finder: "contactPosition",

      wrap: true,
    },
    {
      name: props.t("Contact Company Name"),
      selector: row => row.contactCompanyName,
      sortable: true,
      finder: "contactCompanyName",

      wrap: true,
    },
    {
      name: props.t("Contact Phone"),
      selector: row => row.contactPhone,
      sortable: true,
      finder: "contactPhone",

      wrap: true,
    },
    {
      name: props.t("Contact Gsm"),
      selector: row => row.contactGsm,
      sortable: true,
      finder: "contactGsm",

      wrap: true,
    },
    {
      name: props.t("Contact Email"),
      selector: row => row.contactEmail,
      sortable: true,
      finder: "contactEmail",
      wrap: true,
    },
    {
      name: props.t("Contact Address"),
      selector: row => row.contactAddress,
      sortable: true,
      finder: "contactAddress",
      wrap: true,
    },

  ];




  useEffect(() => {
    const fetchData = async () => {
      try {
        const allData = await getCompanyVisitDetails(id);
        console.log(allData);
        const infoArray = [
          { key: "Document Number", value: allData.documentNumber },
          { key: "Appointment By", value: allData.appointmentBy },
          { key: "Appointment Date", value: allData.appointmentDate ? new Date(allData.appointmentDate).toLocaleDateString() : "" },
          { key: "Previous Evaluation Date", value: allData.previousEvaluationDate ? new Date(allData.previousEvaluationDate).toLocaleDateString() : "" },
          { key: "Location", value: allData.location },
          { key: "Visit Date", value: allData.visitDate ? new Date(allData.visitDate).toLocaleDateString() : "" },
          { key: "Visitor Name", value: allData.visitorName },
          { key: "Contact Name", value: allData.contactName },
          { key: "Contact Position", value: allData.contactPosition },
          { key: "Contact Company Name", value: allData.contactCompanyName },
          { key: "Contact Phone", value: allData.contactPhone },
          { key: "Contact Gsm", value: allData.contactGsm },
          { key: "Contact Email", value: allData.contactEmail },
          { key: "Contact Address", value: allData.contactAddress },
          { key: "Sector Info", value: allData.sectorInfo },
          { key: "Market Status", value: allData.marketStatus },
          { key: "Export Countries", value: allData.exportCountries },
          { key: "Import Status", value: allData.importStatus },
          { key: "Cargo Demand", value: allData.cargoDemand },
          { key: "Next Meeting Date", value: allData.nextMeetingDate ? new Date(allData.nextMeetingDate).toLocaleDateString() : "" },
          { key: "Evaluation", value: allData.evaluation },
        ];

        const contactArray = [
          { key: "Contact Name", value: allData.contactName },
          { key: "Contact Position", value: allData.contactPosition },
          { key: "Contact Company Name", value: allData.contactCompanyName },
          { key: "Contact Phone", value: allData.contactPhone },
          { key: "Contact Gsm", value: allData.contactGsm },
          { key: "Contact Email", value: allData.contactEmail },
          { key: "Contact Address", value: allData.contactAddress },
        ];

        const visitArray = [
          { key: "Document Number", value: allData.documentNumber },
          { key: "Appointment By", value: allData.appointmentBy },
          { key: "Appointment Date", value: allData.appointmentDate ? new Date(allData.appointmentDate).toLocaleDateString() : "" },
          { key: "Previous Evaluation Date", value: allData.previousEvaluationDate ? new Date(allData.previousEvaluationDate).toLocaleDateString() : "" },
          { key: "Location", value: allData.location },
          { key: "Visit Date", value: allData.visitDate ? new Date(allData.visitDate).toLocaleDateString() : "" },
          { key: "Visitor Name", value: allData.visitorName },
        ];

        const meetArray = [
          { key: "Sector Info", value: allData.sectorInfo },
          { key: "Market Status", value: allData.marketStatus },
          { key: "Export Countries", value: allData.exportCountries },
          { key: "Import Status", value: allData.importStatus },
          { key: "Cargo Demand", value: allData.cargoDemand },
          { key: "Next Meeting Date", value: allData.nextMeetingDate ? new Date(allData.nextMeetingDate).toLocaleDateString() : "" },
          { key: "Evaluation", value: allData.evaluation },
        ];

        setContactArray(contactArray);
        setMeetArray(meetArray);
        setVisitArray(visitArray);
        setInfoList(infoArray);

      } catch (error) {
        console.error('Error fetching datas:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("dataList");
    console.log(dataList);
    setFilteredDataList(dataList);
  }, [dataList]);

  useEffect(() => {
    setFilteredInfoList(infoList);
  }, [infoList]);



  useEffect(() => {
    setFilteredInfoList(
      infoList.filter(row =>
        row.key.toLowerCase().includes(searchTextInfo.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(searchTextInfo.toLowerCase())
      )
    );
  }, [searchTextInfo, infoList]);

  useEffect(() => {
    setFilteredDataList(
      dataList?.filter(row =>
        row.documentNumber?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.appointmentBy?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.appointmentDate?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.previousEvaluationDate?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.location?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.visitDate?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.visitorName?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.contactName?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.contactPosition?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.contactCompanyName?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.contactPhone?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.contactGsm?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.contactEmail?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.contactAddress?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.sectorInfo?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.marketStatus?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.exportCountries?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.importStatus?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.cargoDemand?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.nextMeetingDate?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.evaluation?.toString().toLowerCase().includes(searchTextData.toLowerCase())
      )
    );
  }, [searchTextData, dataList]);

  const exportToExcel = () => {
    const column1Name = props.t("Document Number");
    const column2Name = props.t("Appointment By");
    const column3Name = props.t("Appointment Date");
    const column4Name = props.t("Previous Evaluation Date");
    const column5Name = props.t("Location");
    const column6Name = props.t("Visit Date");
    const column7Name = props.t("Visitor Name");
    const column8Name = props.t("Contact Name");
    const column9Name = props.t("Contact Position");
    const column10Name = props.t("Contact Company Name");
    const column11Name = props.t("Contact Phone");
    const column12Name = props.t("Contact Gsm");
    const column13Name = props.t("Contact Email");
    const column14Name = props.t("Contact Address");
    const column15Name = props.t("Sector Info");
    const column16Name = props.t("Market Status");
    const column17Name = props.t("Export Countries");
    const column18Name = props.t("Import Status");
    const column19Name = props.t("Cargo Demand");
    const column20Name = props.t("Next Meeting Date");
    const column21Name = props.t("Evaluation");

    const selectedColumns = filteredDataList.map(datas => ({
      [column1Name]: datas.documentNumber,
      [column2Name]: datas.appointmentBy,
      [column3Name]: datas.appointmentDate,
      [column4Name]: datas.previousEvaluationDate,
      [column5Name]: datas.location,
      [column6Name]: datas.visitDate,
      [column7Name]: datas.visitorName,
      [column8Name]: datas.contactName,
      [column9Name]: datas.contactPosition,
      [column10Name]: datas.contactCompanyName,
      [column11Name]: datas.contactPhone,
      [column12Name]: datas.contactGsm,
      [column13Name]: datas.contactEmail,
      [column14Name]: datas.contactAddress,
      [column15Name]: datas.sectorInfo,
      [column16Name]: datas.marketStatus,
      [column17Name]: datas.exportCountries,
      [column18Name]: datas.importStatus,
      [column19Name]: datas.cargoDemand,
      [column20Name]: datas.nextMeetingDate,
      [column21Name]: datas.evaluation,

    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Company products"));
    XLSX.writeFile(workbook, props.t("Company Product") + ".xlsx");
  };

  const columns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];



  const handlePrint = useReactToPrint({ contentRef });



  const [isHovered, setIsHovered] = useState(false);




  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };



  return (

    <div  >
      <Card>
        <CardBody>




          <div className='d-flex justify-content-between align-items-center'>
            <h5 className="text-primary">{props.t("Company Visit Informations")}</h5>
            <Button
              className="m-0 mx-2"
              color="red"
              onClick={handlePrint}
              onMouseEnter={() => setIsHovered(true)} // Hover başlıyor
              onMouseLeave={() => setIsHovered(false)} // Hover bitiyor
              style={{
                transform: isHovered ? 'scale(1.1)' : 'scale(1)', // Hover durumunda hafif büyütme
                transition: 'all 0.3s ease', // Yumuşak geçiş efekti
              }}
            >
              <FaPrint
                size={30}
                style={{
                  color: isHovered ? 'black' : '#7a6fbe', // Hover durumunda renk değişir
                  transform: isHovered ? 'scale(1.2)' : 'scale(1)', // Hover durumunda ikon büyür
                  transition: 'all 0.3s ease', // Yumuşak geçiş efekti
                }}
              />
            </Button>
          </div>











          <SplitDataTable
            data={infoList}
            columns={columns}
            t={props.t} />
        </CardBody>
      </Card>

      <DynamicPrintCompanyVisit
        t={props.t}
        contentRef={contentRef}
        datas={contactArray}
        datas2={visitArray}
        datas3={meetArray}
        dataColumns={inslugColumns}
        itemDatas={itemData}
        itemColumns={itemColumns}
        actionTitle='Contact İnformations'
        actionTitle2='Visit İnformations'
        actionTitle3='Meet İnformations'

      />


      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

CompanyVisitDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CompanyVisitDetails)