// src/store/selectedCountry/selectedCountryReducer.js
import { SET_COMPANY_VISITS, CLEAR_COMPANY_VISITS } from './actionTypes';

const initialState = {
  list: [], // Başlangıçta seçilen ülke boş
};

const companyVisitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_VISITS:
      return {
        ...state,
        list: action.payload,
      };

    case CLEAR_COMPANY_VISITS:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};

export default companyVisitsReducer;
