import React from 'react';
import Select from 'react-select';
import 'styles/custom/custom-scroolbar.css'

// Dinamik React Select fonksiyonu
const DynamicSelect = ({
  name = '',
  options = null,
  value = null,
  minWidth = null,
  maxWidth = null,
  onChange = () => { },
  placeholder = 'Lütfen bir seçenek seçin',
  isDisabled = false,
  styles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      border: '2px solid #E1AFD1',  // Sabit mavi border
      boxShadow: 'none',  // Focus durumunda gölge eklenmeyecek
      '&:hover': { borderColor: '#7469B6' },  // Hover durumunda da mavi kalacak
      padding: "8px",
      borderRadius: '8px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),  // Menü portalını en üste getir
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#AE6194' // Seçiliyse mavi
        : state.isFocused
          ? '#FECCED94'  // Üzerine gelindiğinde açık mavi
          : '#FDF8FB', // Normalde beyaz
      color: state.isSelected ? '#fff' : '#333', // Yazı rengi
      padding: '6px 12px', // Option içi padding
      position: 'relative', // Çizgiyi konumlandırmak için gerekli
      borderRadius: '8px', // Kenarları yuvarlaklaştır
      marginBottom: '8px', // Alt boşluk
      '&:not(:last-child)::after': {
        content: '""', // Çizgi için boş içerik
        position: 'absolute',
        bottom: '-4px', // Çizgiyi option ortasına yerleştir
        left: '2px', // Çizginin sol boşluğu
        right: '2px', // Çizginin sağ boşluğu
        height: '1px', // Çizginin yüksekliği
        backgroundColor: '#F4ACDC94', // Çizgi rengi
      },
    }),
    
    menu: (base) => ({
      ...base,
      padding: '4px', // Menü içindeki boşluk
      backgroundColor: "#FDF8FB",
      border: '2px solid #AE6194',  // Sabit mavi border
      boxShadow: '0px 8px 12px rgba(0, 0, 0, .3)',
      scrollbarWidth: 'thin', // Firefox için ince scrollbar
      '&::-webkit-scrollbar': {
        width: '32px !important', // Scrollbar genişliği
      },
      '&::-webkit-scrollbar-track': {
        background: '#FDF8FB !important', // Scrollbar arka planı
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#FDF8FB !important', // Scrollbar çubuğu
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#FDF8FB !important', // Hover sırasında scrollbar rengi
      },
    }),
    indicatorSeparator: () => ({ display: 'none' }),  // Yan dik çizgiyi kaldır
    dropdownIndicator: (base) => ({
      ...base,
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      minWidth: minWidth,
      maxWidth: maxWidth,
    }),
    placeholder: (base) => ({
      ...base,
      color: '#999',
    }),
  },
  className = 'shadow bg-white',
  isClearable = true,
  filterOption = null,
  required = false,
  onInputChange = null,
  noOptionsMessage = () => "No record found",

}) => {
  return (
    <Select
      classNamePrefix='custom-select'
      name={name}
      className={className}
      options={options}
      value={value}
      styles={styles}
      onChange={onChange}
      placeholder={placeholder}
      style={{ border: '2px solid #E1AFD1', borderRadius: '8px' }} // Border ve renk burada tanımlandı
      isClearable={isClearable}
      filterOption={filterOption}
      required={required}
      onInputChange={onInputChange}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled} // Select bileşeni devre dışı bırakılır

    />
  );
};

export default DynamicSelect;
