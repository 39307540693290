import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'styles/custom/custom-tooltip.css';

const DeleteButton = ({ onClick, disabled, key }) => {
  const { t } = useTranslation();
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);

  const toggleDeleteTooltip = () => setDeleteTooltipOpen(!deleteTooltipOpen);

  return (
    <div id="deleteButtonTooltipWrapper">
      <Button key={key} color="link" onClick={() => onClick()} disabled={disabled} className='py-0'>
        <FaTrash color={disabled ? "gray" : "red"} size={18} />
      </Button>
      
      <Tooltip
        isOpen={deleteTooltipOpen}
        target="deleteButtonTooltipWrapper"
        toggle={toggleDeleteTooltip}
        placement="top"
        className='custom-tooltip'
      >
        {disabled ? t("No authority") : t("Delete")}
      </Tooltip>
    </div>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

DeleteButton.defaultProps = {
  disabled: false
};

export default DeleteButton;
