import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions';
import PropTypes from "prop-types"
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';

//i18n
import { withTranslation } from "react-i18next"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import { createCountry, getCountries } from 'service/Definitions/countryService';
import { getCities } from 'service/Definitions/cityService';
import { useSelector } from 'react-redux';
import { closeUpdateModal } from 'store/updateModal/actions';
import { hasCreatePermission } from 'service/common/permissionService';
import { clearSelectedUpdateCountry, setSelectedUpdateCountry } from 'store/depot/selectedUpdateCountry/action';
import { clearSelectedUpdateCity, setSelectedUpdateCity } from 'store/depot/selectedUpdateCity/action';
import { customValidationError } from 'components/Common/customValidationError';
import { getCompanyVisits, updateCompanyVisit, } from 'service/visiting/companyVisitService';
import { clearCompanyVisitUpdateFormData, setCompanyVisitUpdateFormData } from 'store/visiting/companyVisitUpdate/action';
import { setCompanyVisits } from 'store/visiting/companyVisitList/action';

const CompanyVisitUpdateForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const formData = useSelector((state) => state.companyVisitUpdateFormData?.formData || {});
    const selectedUpdateCountry = useSelector((state) => state.selectedUpdateCountry?.selectedUpdateCountry || null);
    const selectedUpdateCity = useSelector((state) => state.selectedUpdateCity?.selectedUpdateCity || null);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const countryList = await getCountries();
                const cityList = await getCities();
                console.log(countryList);
                console.log(cities);
                const formattedCities = cityList.map(city => ({
                    value: city.id,
                    label: city.name,
                    country_id: city.country_id
                }));

                const formattedCountries = countryList.map(country => ({
                    value: country.id,
                    label: country.name
                }));
                console.log("**********");
                console.log(formattedCities);
                console.log(formattedCountries);
                setCountries(formattedCountries);
                setCities(formattedCities);
            } catch (error) {
                console.log(`error ${error}`);
            }
        }

        fetchData();
    }, [dispatch]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setCompanyVisitUpdateFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    useEffect(() => {
        console.log("formData güncellendi");
        console.log(formData);
    }, [formData]);

    const handleChangeLowerCase = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        dispatch(setCompanyVisitUpdateFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setCompanyVisitUpdateFormData({
            ...formData,
            ["contactPhone"]: value,
        }));
    };

    const handleGsm = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setCompanyVisitUpdateFormData({
            ...formData,
            ["contactGsm"]: value,
        }));
    };

    const formatDateForInput = (isoDate) => {
        if (!isoDate) {
            return null;
        }
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm()
    };

    const handleSelectCountryChange = (field, selected) => {
        console.log("country change çalıştııı");
        dispatch(clearSelectedUpdateCity());
        if (selected) {
            dispatch(setSelectedUpdateCountry(selected));
            dispatch(setCompanyVisitUpdateFormData({ ...formData, [field]: selected.value, ["city_id"]: null }));

        } else {
            dispatch(clearSelectedUpdateCountry());
            dispatch(setCompanyVisitUpdateFormData({ ...formData, [field]: null, ["city_id"]: null }));
        }
    };

    const handleSelectCityChange = (field, selected) => {
        dispatch(setSelectedUpdateCity(selected));
        dispatch(setCompanyVisitUpdateFormData({ ...formData, [field]: selected?.value }));
    };

    const handleCreateCountry = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: <span>{props.t("modal-save-body")}</span>,
                onConfirm: async () => {
                    // Yeni bir şirket oluşturmak için:
                    const newCountry = {
                        name: inputValue,  // Kullanıcının girdiği değer
                    };
                    await createCountry(newCountry)
                        .then(async (addedCountry) => {
                            const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                            const newSelected = newCountryList.find(data => data.id === addedCountry.id);
                            const formattedCountries = newCountryList.map(country => ({
                                value: country.id,
                                label: country.name
                            }));
                            setCountries(formattedCountries);
                            dispatch(setSelectedUpdateCountry({ value: newSelected.id, label: newSelected.name }));
                            dispatch(setCompanyVisitUpdateFormData({ ...formData, ["country_id"]: newSelected.id, ["city_id"]: null }));
                            dispatch(clearSelectedUpdateCity());

                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        })
                        .catch((error) => {
                            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                        }); dispatch(closeModal());
                }
            }));// Modalı açıyoruz
    }


    const handleConfirm = async () => {
        console.log("company add Form Data handle Confirm");
        console.log(formData);
        dispatch(
            openModal({
                title: props.t("modal-update-title"),
                body: props.t("modal-update-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after successful update
                    const { createdAt, updatedAt, country_name, city_name, is_deleted, client_no, ...rest } = formData;
                    console.log("update confirm formdata");
                    console.log(formData);

                    console.log("reessstttt");
                    console.log(rest);
                    await updateCompanyVisit(rest)
                        .then(async () => {
                            toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                            console.log(formData);
                            const updatedCompanies = await getCompanyVisits(); // Fetch updated companies
                            dispatch(setCompanyVisits(updatedCompanies)); // Update Redux state
                            dispatch(clearSelectedUpdateCity());
                            dispatch(clearSelectedUpdateCountry());
                            dispatch(clearCompanyVisitUpdateFormData());
                            dispatch(closeUpdateModal());
                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            //toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                            customValidationError(error, props);
                        });
                }
            })
        );

    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update Company Visit")}</h5>
                    <Button className='text-white' color="warning" type="submit">
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="documentNumber">{props.t("Document Number")}</Label>
                            <DynamicInput
                                type="text"
                                name="documentNumber"
                                id="documentNumber"
                                value={formData.documentNumber}
                                onChange={handleChange}
                                placeholder={props.t("Enter document number")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="appointmentBy">{props.t("Appointment By")}</Label>
                            <DynamicInput
                                type="text"
                                name="appointmentBy"
                                id="appointmentBy"
                                value={formData.appointmentBy}
                                onChange={handleChange}
                                placeholder={props.t("Enter appointment by")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="appointmentDate">{props.t("Appointment Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="appointmentDate"
                                name="appointmentDate"
                                value={formatDateForInput(formData.appointmentDate)}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="previousEvaluationDate">{props.t("Previouse Evaluation Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="previousEvaluationDate"
                                name="previousEvaluationDate"
                                value={formatDateForInput(formData.previousEvaluationDate)}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="location">{props.t("Location")}</Label>
                            <DynamicInput
                                type="text"
                                name="location"
                                id="location"
                                value={formData.location}
                                onChange={handleChange}
                                placeholder={props.t("Enter location")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="visitDate">{props.t("Visit Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="visitDate"
                                name="visitDate"
                                value={formatDateForInput(formData.visitDate)}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="visitorName">{props.t("Visitor Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="visitorName"
                                id="visitorName"
                                value={formData.visitorName}
                                onChange={handleChange}
                                placeholder={props.t("Enter visitor name")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="contactName">{props.t("Contact Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactName"
                                id="contactName"
                                value={formData.contactName}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact name")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="contactPosition">{props.t("Contact Position")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactPosition"
                                id="contactPosition"
                                value={formData.contactPosition}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact position")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>




                    <Col md={4}>
                        <FormGroup>
                            <Label for="contactCompanyName">{props.t("Contact Company Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactCompanyName"
                                id="contactCompanyName"
                                value={formData.contactCompanyName}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact company name")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="contactPhone">{props.t("Contact Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter contact phone number")}
                                value={formData.contactPhone}
                                minLength={6}
                                maxLength={24}
                                onChange={value => handlePhone(value)} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="contactGsm">{props.t("Contact GSM")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter contact GSM number")}
                                value={formData.contactGsm}
                                minLength={6}
                                maxLength={24}
                                onChange={value => handleGsm(value)} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="contactEmail">{props.t("Contact Email")}</Label>
                            <DynamicInput
                                type="email"
                                name="contactEmail"
                                id="contactEmail"
                                value={formData.contactEmail}
                                onChange={handleChangeLowerCase}
                                placeholder={props.t("Enter contact email address")}
                                minLength={3}
                                maxLength={64}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="contactAddress">{props.t("Contact Address")}</Label>
                            <DynamicInput
                                type="text"
                                name="contactAddress"
                                id="contactAddress"
                                value={formData.contactAddress}
                                onChange={handleChange}
                                placeholder={props.t("Enter contact address")}
                                maxLength={255}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="sectorInfo">{props.t("Sector Info")}</Label>
                            <DynamicInput
                                type="text"
                                name="sectorInfo"
                                id="sectorInfo"
                                value={formData.sectorInfo}
                                onChange={handleChange}
                                placeholder={props.t("Enter sector info")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="marketStatus">{props.t("Market Status")}</Label>
                            <DynamicInput
                                type="text"
                                name="marketStatus"
                                id="marketStatus"
                                value={formData.marketStatus}
                                onChange={handleChange}
                                placeholder={props.t("Enter market status")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                    {/*                     <Col md={4}>
                        <FormGroup>
                            <Label for="exportCountries">{props.t("Export Countries")}</Label>
                            <DynamicCreatableSelect
                                options={countries}
                                value={countries.find(data => data.value === formData.country_id || null)}
                                onChange={(selected) => handleSelectCountryChange("country_id", selected)}
                                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                                placeholder={props.t("Enter export countries")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col> */}
                    <Col md={4}>
                        <FormGroup>
                            <Label for="exportCountries">{props.t("Export Countries")}</Label>
                            <DynamicInput
                                type="text"
                                name="exportCountries"
                                id="exportCountries"
                                value={formData.exportCountries}
                                onChange={handleChange}
                                placeholder={props.t("Enter export countries")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="importStatus">{props.t("Import Status")}</Label>
                            <DynamicInput
                                type="text"
                                name="importStatus"
                                id="importStatus"
                                value={formData.importStatus}
                                onChange={handleChange}
                                placeholder={props.t("Enter import status")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="cargoDemand">{props.t("Cargo Demand")}</Label>
                            <DynamicInput
                                type="text"
                                name="cargoDemand"
                                id="cargoDemand"
                                value={formData.cargoDemand}
                                onChange={handleChange}
                                placeholder={props.t("Enter cargo demand")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="nextMeetingDate">{props.t("Next Meeting Date")}</Label>
                            <DynamicInput
                                type="date"
                                id="nextMeetingDate"
                                name="nextMeetingDate"
                                value={formatDateForInput(formData.nextMeetingDate)}
                                onChange={handleChange}
                                onClick={(e) => e.target.showPicker()}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="evaluation">{props.t("Evaluation")}</Label>
                            <DynamicInput
                                type="text"
                                name="evaluation"
                                id="evaluation"
                                value={formData.evaluation}
                                onChange={handleChange}
                                placeholder={props.t("Enter evaluation")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

CompanyVisitUpdateForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(CompanyVisitUpdateForm)

