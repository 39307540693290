import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Card } from "reactstrap"
import { useSelector } from "react-redux"
import { hasSidebarPermission, hasViewPermission } from "service/common/permissionService"
import { setPermissions } from "store/permissions/action"
import { useDispatch } from "react-redux"
import 'styles/custom/sidebar-style.css'

const SidebarContent = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions);
  const [dashboardPermission, setDashboardPermission] = useState(false);
  const [depotPermission, setDepotPermission] = useState(false);

  const [companiesPermission, setCompaniesPermission] = useState(false);
  const [companyPermission, setCompanyPermission] = useState(false);
  const [externCompanyPermission, setExternCompanyPermission] = useState(false);

  const [productPermission, setProductPermission] = useState(false);
  const [productsPagePermission, setProductsPagePermission] = useState(false);
  const [productCategoryPermission, setProductCategoryPermission] = useState(false);
  const [clientProductPermission, setClientProductPermission] = useState(false);
  const [depotTrashPermission, setDepotTrashPermission] = useState(false);

  const [definitionsPermission, setDefinitionsPermission] = useState(false);
  const [countryPermisison, setCountryPermission] = useState(false);
  const [cityPermission, setCityPermission] = useState(false);

  const [settingsPermission, setSettingsPermission] = useState(false);
  const [settingUserPermission, setSettingUserPermission] = useState(false);
  const [settingRolePermission, setSettingRolePermission] = useState(false);
  const [settingLanguagePermission, setSettingLanguagePermission] = useState(false);
  const [settingLogsPermission, setsettingLogsPermission] = useState(false);

  const [workOrderPermission, setWorkOrderPermission] = useState(false);
  const [inslugPermission, setInslugPermission] = useState(false);
  const [uitslugPermission, setUitslugPermission] = useState(false);
  const [helevenPermission, setHelevenPermission] = useState(false);

  const [warehousePermission, setWarehousePermission] = useState(false);


  useEffect(() => {
    const fetchPermissions = async () => {
      const permissionData = localStorage.getItem("permissions");
      const permArray = JSON.parse(permissionData)
      await fillPermissions(permArray);
      dispatch(setPermissions(permArray));
    }
    fetchPermissions();
  }, []);

  const fillPermissions = async (permArray) => {
    // DS_00 Dashboard
    setDashboardPermission(await hasSidebarPermission(permArray, "DS_00"));
    // DE_00 Depot
    setDepotPermission(await hasSidebarPermission(permArray, "DE_00"));
    setDepotTrashPermission(await hasSidebarPermission(permArray, "DE"));

    // DE_01, DE_02, DE_16 Company
    setCompaniesPermission(await hasSidebarPermission(permArray, "DE_12"));
    setCompanyPermission(await hasSidebarPermission(permArray, "DE_01"));
    setExternCompanyPermission(await hasSidebarPermission(permArray, "DE_02"));

    // DE_06 , DE_07 , DE_08 , DE_09 Product
    setProductPermission(await hasSidebarPermission(permArray, "DE_06"));
    setProductCategoryPermission(await hasSidebarPermission(permArray, "DE_07"));
    setProductsPagePermission(await hasSidebarPermission(permArray, "DE_08"));
    setClientProductPermission(await hasSidebarPermission(permArray, "DE_09"));

    // DE_10 , DE_11 , DE_12 Definitions
    setDefinitionsPermission(await hasSidebarPermission(permArray, "DE_10"));
    setCountryPermission(await hasSidebarPermission(permArray, "DE_11"));
    setCityPermission(await hasSidebarPermission(permArray, "DE_12"));

    // MM_00 , MM_01 , MM_02 , MM_03 , MM_04 Settings
    setSettingsPermission(await hasSidebarPermission(permArray, "MM_00"));
    setSettingUserPermission(await hasSidebarPermission(permArray, "MM_01"));
    setSettingRolePermission(await hasSidebarPermission(permArray, "MM_02"));
    setSettingLanguagePermission(await hasSidebarPermission(permArray, "MM_05"));
    setsettingLogsPermission(await hasSidebarPermission(permArray, "MM_04"));

    // DE_16, DE_03, DE_04, DE_05 Work Orders
    setWorkOrderPermission(await hasSidebarPermission(permArray, "DE_13"));
    setInslugPermission(await hasSidebarPermission(permArray, "DE_03"));
    setUitslugPermission(await hasSidebarPermission(permArray, "DE_04"));
    setHelevenPermission(await hasSidebarPermission(permArray, "DE_05"));

    // DE_11 Warehouse
    setWarehousePermission(await hasSidebarPermission(permArray, "DE_11"));

  }

  useEffect(() => {
  }, [permissions]);

  useEffect(() => {
  }, [cityPermission]);

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const depot =
    <li>
      <Link to="/depot-main" className="has-arrow waves-effect">
        <i className="mdi mdi-warehouse"></i>
        <span>{props.t("Depot")}</span>
      </Link>
      <ul className="sub-menu">
        <Card className="card-container mx-2 my-1 rounded rounded-lg shadow">

          {depotPermission ?
            <li>
              <Link to="/depot">
                <i className="mdi mdi-monitor-dashboard"></i>
                {props.t("Dashboard")}
              </Link>
            </li>
            : null}

          {workOrderPermission ?
            <li>
              <Link to="/work-orders" className="has-arrow">
                <i className="mdi mdi-clipboard-text-outline"></i>
                {props.t("Work Orders")}
              </Link>
              <ul>
                <li>
                  {inslugPermission ?
                    <Link to="/inslugs">
                      <i className="mdi mdi-record"></i>
                      {props.t("Inslug")}
                    </Link>
                    : null}
                </li>
                <li>
                  {uitslugPermission ?
                    <Link to="/uitslugs">
                      <i className="mdi mdi-record"></i>
                      {props.t("Uitslug")}
                    </Link>
                    : null}
                </li>
                <li>
                  {helevenPermission ?
                    <Link to="/hevelens">
                      <i className="mdi mdi-record"></i>
                      {props.t("Hevelen")}
                    </Link>
                    : null}
                </li>
              </ul>
            </li>
            : null}

          {companiesPermission ?
            <li>
              <Link to="/companies" className="has-arrow">
                <i className="mdi mdi-office-building-outline"></i>
                {props.t("Companies")}
              </Link>
              <ul>
                <li>
                  {companyPermission ?
                    <Link to="/company">
                      <i className="mdi mdi-record"></i>
                      {props.t("Company")}
                    </Link>
                    : null
                  }
                </li>
                <li>
                  {externCompanyPermission ?
                    <Link to="/extern-company">
                      <i className="mdi mdi-record"></i>
                      {props.t("Extern Company")}
                    </Link>
                    : null
                  }
                </li>
              </ul>
            </li>
            : null}

          {productPermission ?
            <li>
              <Link to="/products" className="has-arrow">
                <i className="mdi mdi-cube-outline"></i>
                {props.t("Product")}
              </Link>

              <ul>
                <li>
                  {productsPagePermission ?
                    <Link to="/product">
                      <i className="mdi mdi-record"></i>
                      {props.t("Products")}
                    </Link>
                    : null}
                </li>
                <li>
                  {productCategoryPermission ?
                    <Link to="/product-group">
                      <i className="mdi mdi-record"></i>
                      {props.t("Product Categories")}
                    </Link>
                    : null}
                </li>
                <li>
                  {clientProductPermission ?
                    <Link to="/client-product">
                      <i className="mdi mdi-record"></i>
                      {props.t("Company Products")}
                    </Link>
                    : null}
                </li>
              </ul>
            </li>
            : null}
          <li>
            {definitionsPermission ?
              <Link to="/depot/definition-item/-1">
                <i className="mdi mdi-information-outline"></i>
                {props.t("Definitions")}
              </Link>
              : null}
          </li>
          <li>
            {definitionsPermission ?
              <Link to="/stock">
                <i className="mdi mdi-information-outline"></i>
                {props.t("Stockitions")}
              </Link>
              : null}
          </li>
          {depotTrashPermission ?
            <li>
              <Link to="/depot/trash">
                <i className="mdi mdi-trash-can"></i>
                <span>{props.t("Trash")}</span>
              </Link>
            </li>
            : null
          }

          {warehousePermission ?
            <li className="my-1">
              <Link to="/depots" className="waves-effect">
                <i className="mdi mdi-warehouse"></i>
                {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                <span>{props.t("Warehouse")}</span>
              </Link>
            </li>
            : null
          }
        </Card>
      </ul>
    </li>;

  const settings =
    <li>
      <Link to="/settings" className="has-arrow waves-effect">
        <i className="mdi mdi-saw-blade"></i>
        <span>{props.t("Settings")}</span>
      </Link>
      <ul className="sub-menu" >
        <Card className="card-container mx-2 my-1 rounded rounded-lg shadow">
          <li>
            {settingUserPermission ?
              <Link to="/settings/users">
                <i className="mdi mdi-account"></i>
                {props.t("Users")}
              </Link>
              : null}
          </li>
          <li>
            {settingRolePermission ?
              <Link to="/settings/roles">
                <i className="mdi mdi-shield-account"></i>
                {props.t("Roles")}
              </Link>
              : null}
          </li>
          <li>
            {settingLanguagePermission ?
              <Link to="/settings/language">
                <i className="mdi mdi-translate"></i>
                {props.t("Language")}
              </Link>
              : null}
          </li>
          <li>
            {settingLogsPermission ?
              <Link to="/settings/logs">
                <i className="mdi mdi-clipboard-text"></i>
                {props.t("Logs")}
              </Link> : null}
          </li>
        </Card>
      </ul>
    </li>;

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%", padding: "4px" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="my-1">
              {hasSidebarPermission(permissions, "DS_00") ?
                <Link to="/dashboard" className="waves-effect">
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Main Menu")}</span>
                </Link>
                : null}
            </li>
            <li>
              {hasSidebarPermission(permissions, "DS_00") ?
                <Link to="/depot-main" className="has-arrow waves-effect">
                  <i className="mdi mdi-warehouse"></i>
                  <span>{props.t("Warehouse")}</span>
                </Link>
                : null}
              <ul className="sub-menu">
                {depotPermission ?
                  <li>
                    <Link to="/depot">
                      <i className="mdi mdi-monitor-dashboard"></i>
                      {props.t("Dashboard")}
                    </Link>
                  </li>
                  : null}

                <li>
                  {hasSidebarPermission(permissions, "DE_13") ?
                    < Link to="/work-orders" className="has-arrow">
                      <i className="mdi mdi-clipboard-text-outline"></i>
                      {props.t("Work Orders")}
                    </Link>
                    : null}
                  <ul>
                    <li>
                      {inslugPermission ?
                        <Link to="/inslugs">
                          <i className="mdi mdi-record"></i>
                          {props.t("Inslug")}
                        </Link>
                        : null}
                    </li>
                    <li>
                      {uitslugPermission ?
                        <Link to="/uitslugs">
                          <i className="mdi mdi-record"></i>
                          {props.t("Uitslug")}
                        </Link>
                        : null}
                    </li>
                    <li>
                      {helevenPermission ?
                        <Link to="/hevelens">
                          <i className="mdi mdi-record"></i>
                          {props.t("Hevelen")}
                        </Link>
                        : null}
                    </li>
                  </ul>
                </li>

                <li>
                  {hasSidebarPermission(permissions, "DE_12") ?
                    <Link to="/companies" className="has-arrow">
                      <i className="mdi mdi-office-building-outline"></i>
                      {props.t("Companies")}
                    </Link>
                    : null}
                  <ul>
                    <li>
                      {companyPermission ?
                        <Link to="/company">
                          <i className="mdi mdi-record"></i>
                          {props.t("Company")}
                        </Link>
                        : null
                      }
                    </li>
                    <li>
                      {externCompanyPermission ?
                        <Link to="/extern-company">
                          <i className="mdi mdi-record"></i>
                          {props.t("Extern Company")}
                        </Link>
                        : null
                      }
                    </li>
                  </ul>
                </li>

                <li>
                  {hasSidebarPermission(permissions, "DE_06") ?
                    <Link to="/products" className="has-arrow">
                      <i className="mdi mdi-cube-outline"></i>
                      {props.t("Product")}
                    </Link>
                    : null}
                  <ul>
                    <li>
                      {productsPagePermission ?
                        <Link to="/product">
                          <i className="mdi mdi-record"></i>
                          {props.t("Products")}
                        </Link>
                        : null}
                    </li>
                    <li>
                      {productCategoryPermission ?
                        <Link to="/product-group">
                          <i className="mdi mdi-record"></i>
                          {props.t("Product Categories")}
                        </Link>
                        : null}
                    </li>
                    <li>
                      {clientProductPermission ?
                        <Link to="/client-product">
                          <i className="mdi mdi-record"></i>
                          {props.t("Company Products")}
                        </Link>
                        : null}
                    </li>
                  </ul>
                </li>
                {warehousePermission ?
                  <li className="my-1">
                    <Link to="/depots" className="waves-effect">
                      <i className="mdi mdi-warehouse"></i>
                      {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                      <span>{props.t("Depot")}</span>
                    </Link>
                  </li>
                  : null
                }
                <li>
                  {definitionsPermission ?
                    <Link to="/stock">
                      <i className="mdi mdi-database"></i>
                      {props.t("Stock")}
                    </Link>
                    : null}
                </li>
                <li>
                  {definitionsPermission ?
                    <Link to="/depot/definition-item/-1">
                      <i className="mdi mdi-information-outline"></i>
                      {props.t("Definitions")}
                    </Link>
                    : null}
                </li>
                {depotTrashPermission ?
                  <li>
                    <Link to="/depot/trash">
                      <i className="mdi mdi-trash-can"></i>
                      <span>{props.t("Trash")}</span>
                    </Link>
                  </li>
                  : null
                }
              </ul>
            </li>

            <li>
              {hasSidebarPermission(permissions, "MM_00") ?
                <Link to="/settings" className="has-arrow waves-effect">
                  <i className="mdi mdi-cash-multiple"></i>
                  <span>{props.t("Accounting")}</span>
                </Link>
                : null}
              <ul className="sub-menu">
                <li>
                  {settingUserPermission ?
                    <Link to="/accounting/income">
                      <i className="mdi mdi-cash-plus"></i>
                      {props.t("Income")}
                    </Link>
                    : null}
                </li>
                <li>
                  {settingRolePermission ?
                    <Link to="/accounting/outcome">
                      <i className="mdi mdi-cash-minus"></i>
                      {props.t("Outcome")}
                    </Link>
                    : null}
                </li>
                <li>
                  {settingRolePermission ?
                    <Link to="/accounting/my-company">
                      <i className="mdi mdi-shield-account"></i>
                      {props.t("My Company")}
                    </Link>
                    : null}
                </li>
                <li>
                  {settingRolePermission ?
                    <Link to="/accounting/current">
                      <i className="mdi mdi-account-cash"></i>
                      {props.t("Currents")}
                    </Link>
                    : null}
                </li>
              </ul>
            </li>

            <li>
              {hasSidebarPermission(permissions, "MM_00") ?
                <Link to="/settings" className="has-arrow waves-effect">
                  <i className="mdi mdi-saw-blade"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
                : null}
              <ul className="sub-menu">
                <li>
                  {settingUserPermission ?
                    <Link to="/settings/users">
                      <i className="mdi mdi-account"></i>
                      {props.t("Users")}
                    </Link>
                    : null}
                </li>
                <li>
                  {settingRolePermission ?
                    <Link to="/settings/roles">
                      <i className="mdi mdi-shield-account"></i>
                      {props.t("Roles")}
                    </Link>
                    : null}
                </li>
                <li>
                  {settingLanguagePermission ?
                    <Link to="/settings/language">
                      <i className="mdi mdi-translate"></i>
                      {props.t("Language")}
                    </Link>
                    : null}
                </li>
                <li>
                  {settingLogsPermission ?
                    <Link to="/settings/logs">
                      <i className="mdi mdi-clipboard-text"></i>
                      {props.t("Logs")}
                    </Link> : null}
                </li>
              </ul>
            </li>

            <li>
              <Link to="/support" className=" waves-effect">
                <i className="mdi mdi-account-cog"></i>
                <span>{props.t("Support")}</span>
              </Link>
            </li>

            <li>
              <Link to="/guide" className=" waves-effect">
                <i className="mdi mdi-teach"></i>
                <span>{props.t("Guide")}</span>
              </Link>
            </li>

            <li>
              <Link to="/visiting/company-visit" className="waves-effect">
                <i className="mdi mdi-briefcase"></i>
                <span>{props.t("Company Visit")}</span>
              </Link>
            </li>


            {/* <li>
              <Link to="/calendar" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Takvim")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-email-outline"></i>
                <span>{props.t("Mail")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/email-inbox">{props.t("Gelen Kutusu")}</Link>
                </li>
                <li>
                  <Link to="/email-read">{props.t("Okunan Mailler")} </Link>
                </li>
                <li>
                  <Link to="/email-compose">{props.t("Mail Gönderme")} </Link>
                </li>
              </ul>
            </li> */}

            {/*  <li>
              <Link to="/chat" className=" waves-effect">
                <i className="mdi mdi-chat-processing-outline"></i>
                <span className="badge rounded-pill bg-danger float-end">Hot</span>
                <span>Chat</span>
              </Link>
            </li> */}

            {/* <li className="menu-title">{props.t("Components")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-buffer"></i>
                <span>{props.t("UI Elements")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-badge">{props.t("Badge")}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-utilities">Utilities<span className="badge rounded-pill bg-success float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">Colors<span className="badge rounded-pill bg-warning float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-offcanvas">Offcanvas<span className="badge rounded-pill bg-warning float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-pagination">{props.t("Pagination")}</Link>
                </li>
                <li>
                  <Link to="/ui-popover-tooltip">{props.t("Popover & Tooltips")}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">{props.t("Range Slider")}</Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">{props.t("Session Timeout")}</Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">{props.t("Progress Bars")}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">{props.t("Tabs & Accordions")}</Link>
                </li>
                <li>
                  <Link to="/ui-typography">{props.t("Typography")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t("Video")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="mdi mdi-clipboard-outline"></i>
                <span className="badge rounded-pill bg-success float-end">6</span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">{props.t("Form Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">{props.t("Form Validation")}</Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">{props.t("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="/form-xeditable">{props.t("Form Xeditable")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-chart-line"></i>
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">
                    {props.t("Apex charts")}
                  </Link>
                </li>

                <li>
                  <Link to="/charts-chartjs" className="dropdown-item">
                    {props.t("Chartjs Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/sparkline-charts" className="dropdown-item">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-c3" className="dropdown-item">
                    {props.t("C3 Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-knob" className="dropdown-item">
                    {props.t("Jquery Knob Chart")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-format-list-bulleted-type"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t("Data Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">
                    {props.t("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="/tables-editable">{props.t("Editable Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-album"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-ion">{props.t("Ion Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t("Font Awesome")}</Link>
                </li>
                <li>
                  <Link to="/icons-themify">{props.t("Themify Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-typicons">{props.t("Typicons Icons")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-danger float-end">2</span>
                <i className="mdi mdi-google-maps"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t("Vector Maps")}</Link>
                </li>

              </ul>
            </li>

            <li className="menu-title">Extras</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-box"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{props.t("Lock Screen")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-text-box-multiple-outline"></i>
                <span>{props.t("Extra Pages")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-timeline">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/pages-invoice">{props.t("Invoice")}</Link>
                </li>
                <li>
                  <Link to="/pages-directory">{props.t("Directory")}</Link>
                </li>
                <li>
                  <Link to="/pages-blank">{props.t("Blank Page")}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-share-variant"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar >
    </React.Fragment >
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
