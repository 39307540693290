// src/store/selectedCountry/selectedCountryActions.js
import { SET_COMPANY_VISITS, CLEAR_COMPANY_VISITS, UPDATE_UITSLUG_ITEM } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setCompanyVisits = (city) => ({
  type: SET_COMPANY_VISITS,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearCompanyVisits = () => ({
  type: CLEAR_COMPANY_VISITS,
});


