// /src/services/inslugService.js
import axios from 'axios';
import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const DEPOT = '/depot';
const HEVELEN = '/hevelen';
const INFO = '/info';
const UPDATE_INFO = '/update-info';
const HEVELEN_GET_ONE = '/one-hevelen-with-items';
const GET = '/get';
const CREATE = '/create';
const UPDATE = '/update';
const DELETE = '/delete';
const GET_DELETED = '/get-deleted';
const RESTORE = '/restore';
const UITSLUG_BY_COMPANY = '/info';
const STOCK_VIEW = '/stock-view';
const APPROVE = '/approve';

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} data - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const createHeleven = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${CREATE}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} id - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const getHeleven = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${HEVELEN_GET_ONE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getHelevens = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${GET}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedHelevens = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${GET_DELETED}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug silme fonksiyonu
 * @param {string} id - Şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getHelevensByCompany = async (client_no) => {
  //return generateFakeData(9);
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${UITSLUG_BY_COMPANY}`, { client_no });
    console.log("responsedata");
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const helevenInfo = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${INFO}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const getHelevenAllData = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${UPDATE_INFO}`, {id});
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const getHelevenItemDataWithQr = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${STOCK_VIEW}`, {id});
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Inslug item getirilirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Belirli bir inslug'ı ID'ye göre güncelleme fonksiyonu
 * @param {Object} data - Güncellenmiş Inslug verileri
 * @returns {Promise} - API cevabı
 */
export const updateHeleven = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteHeleven = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${DELETE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug geri getirme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const approveHeleven = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${APPROVE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug geri getirme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreHeleven = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${HEVELEN}${RESTORE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

